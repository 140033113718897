//@import '../../../../assets/sass/styles.scss';
@import "../../../assets/themes/default/share.scss";
@import "../../../assets/sass/global-import";

.hidefile {
  display: none;
}

.MuiBox-root.MuiBox-root {
  padding: 0px;
}

.dashboard-cover {
  max-height: calc(100vh - 70px);
  @include custom-scroller("y");

}

.Innerdetails {
  .fileList {
    .MuiTableCell-head:last-child {
      float: left;
    }
  }
}

.MuiDialogTitle-root {
  margin: 0;
  padding: 5px 15px !important;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  color: #032353;

  .MuiTypography-h6 {
    font-size: $icon_font_size;
    font-weight: 600;
  }

  .MuiIconButton-root {
    color: #032353;

    .MuiSvgIcon-root {
      font-size: 16px;
      font-weight: bold;
      color: $primary;
    }
  }
}