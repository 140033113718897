@import "../../../../assets/sass/styles.scss";

.header-page {
  flex-flow: 1;

  .title {
    flex-grow: 1;
  }

  .username {
    height: 25px;
    width: 25px;
    border-radius: 30px;
    border: 1px solid;
    background-color: white;
    color: blue;
    font-family: Helvetica, sans-serif;

    div {
      align-items: center;
      font-size: 13px;
      margin-top: 4px;
    }
  }

  span.header-logo img {
    height: 33px;
  }

  .MuiAppBar-colorPrimary {
    color: $secondary;
    background-color: $primary;

    @media screen and (max-width: 767px) {
      padding-left: 0px;
      padding-right: 0px;
    }

    .MuiToolbar-gutters {
      padding: 0 11px;
      min-height: 45px;
    }

    img {
      // width: 16%;
      margin-right: 15px;

      @media screen and (max-width: 767px) {
        margin-left: 10px;
      }
    }

    // & span {
    //   font-weight: 400;
    //   font-size: 20px;
    // }

    // .toolbarStyle-open {
    //   margin-left: 10%;
    // }
    // .toolbarStyle-close {
    //   margin-left: 3%;
    // }
    // .dt-header__details {
    //   display: flex;
    //   flex-flow: row-reverse;
    //   text-align: center;
    //   align-items: center;
    //   justify-content: space-between;
    //   padding: 0px 10px;
    //   span {
    //     font-weight: 600;
    //   }
    //   button {
    //     padding: 0px 4px;
    //   }
    // }
  }
}

div[class^="MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded"] {
  background-color: $secondary;
  width: 11%;

  @media screen and (max-width: 767px) {
    width: 33%;
  }

  .MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;

    .MuiMenuItem-root {
      padding-top: 0px;
      padding-bottom: 0px;

      .MuiListItemIcon-root {
        min-width: 30px;

        svg.MuiSvgIcon-root {
          font-size: 20px;
          color: $primary;
        }
      }

      .MuiListItemText-root {
        span {
          font-size: 14px;
          color: $primary;
        }
      }
    }

    // & .MuiToolbar-gutters {
    //   background: $primary !important;
    //   padding-left: 0px !important;
    //   padding-right: 0px !important;
    //   @media screen and (min-width: 600px) {
    //     padding-left: 0px !important;
    //     padding-right: 0px !important;
    //   }
    // }
  }
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  margin-top: 32px;
  width: 15%;
  @media screen and (max-width: 900px) {
  width: 200px;
  }
}

.MuiIconButton-root:hover {
  background-color: transparent !important;
}

tr.MuiTableRow-root.MuiTableRow-head {
  background: #ccc;

  th.MuiTableCell-root.MuiTableCell-head {
    font-weight: 600;
  }
}