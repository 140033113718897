@import "../../../../assets/themes/default/share.scss";
@import "../../../../assets/sass/styles.scss";

:root {
  --font-text-size: 12px;
  --background-color: #fff;
  --font-color: #000000;
  --border_color: #006eaf;
}

.dynamic-list {
  .filter-block {
    display: none;
    justify-content: space-between;
    padding: 0px 10px;

    .dropdown-filter {
      margin: $font-text-size;
    }

    .MuiOutlinedInput-input {
      padding: 8.5px 0px !important;
      margin: 0px 15px;
      font-size: var(--font-text-size) !important;
    }

    .MuiInputLabel-formControl {
      top: -11px;
    }
  }

  .search-cover {
    @media screen and (max-width: 767px) {
      width: 45%;
    }

    label#standard-uncontrolled-label {
      font-size: var(--font-text-size) !important;
    }

    .MuiSvgIcon-colorPrimary {
      font-size: $font_icon;
    }
  }

  .dynamic-table {
    overflow-y: auto;
    //height: 415px;
    max-height: calc(93vh - 170px);
    overflow-x: auto;
    @include scroller();

    @media screen and (max-width: 767px) {
      // overflow-y: scroll;
      max-width: 500px;
    }

    &.bpm-table {
      max-height: calc(93vh - 170px);
    }

    &.envelopelist-table {
      max-height: calc(87vh - 170px);
    }

    .MuiTableCell-head:last-child {
      float: right;
    }

    .table-sort {
      display: flex;
      text-align: left;
      align-items: center;
      text-transform: capitalize;
      // color: $secondary;
      color: var(--font-color);
      font-size: var(--font-text-size) !important;

      & .MuiTableCell-head {
        color: $primary;
      }

      .MuiTableCell-head:last-child {
        float: right;
        border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
      }

      .sort-icons {
        display: flex;
        cursor: pointer;

        .arrowicon {
          max-height: 14px;
          color: #c2c0bd;

          &:hover {
            color: $primary;
          }
        }

        .arrowiconactive {
          max-height: 14px;
          color: $primary;
        }

        .icon-asc {
          margin: 0px;
          padding: 0px;
          max-width: 100%;
          width: 30%;
          max-height: 100px;
          height: $font-text-size;
          margin-right: -2px;

          &:hover {
            background: #ffffff80;
            border-radius: 100px;
          }
        }

        .icon-desc {
          margin: 0px;
          padding: 0px;
          max-width: 100%;
          width: 30%;
          max-height: 100px;
          height: $font-text-size;

          &:hover {
            background: #ffffff80;
            border-radius: 100px;
          }
        }
      }
    }

    .MuiTableCell-root {
      background: var(--background-color);
      color: var(--font-color);
      padding: 4px 14px;
      font-size: var(--font-text-size) !important;
      border-right: 1px solid var(--border_color);
    }

    .checked-user {
      .PrivateSwitchBase-root-306 {
        padding: 2px;
        height: 13px;
      }

      .MuiIconButton-colorPrimary {
        padding: 2px;
        height: 13px;
      }

      .MuiSvgIcon-colorPrimary {
        color: white;
        background: $primary;
        border-radius: 4px;
        text-align: center;
        padding: 1px 1px;
        font-size: 15px;
        margin: 0px 3px;
      }

      svg.MuiSvgIcon-root {
        height: 20px;
      }
    }

    .change-password {
      text-align: left;

      .MuiSvgIcon-colorPrimary {
        color: white;
        background: $primary;
        border-radius: 4px;
        text-align: center;
        padding: 1px 1px;
        font-size: 15px;
        margin: 0px 3px;
      }
    }
  }

  .MuiTablePagination-root:last-child {
    display: flex;
    justify-content: space-around;

    .MuiTablePagination-caption {
      font-weight: 600;
      color: $primary;
    }

    & .MuiSelect-select.MuiSelect-select {
      font-weight: 600;
      color: $primary;
    }

    .MuiIconButton-label {
      width: 100%;
      display: flex;
      align-items: inherit;
      justify-content: inherit;
      color: $primary;
    }
  }
}

.MuiTableBody-root {
  .MuiTableCell-root {
    padding: 6px 10px !important;

    span {
      //display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .action-icon {
    float: right;
    align-items: center;
    text-align: center;
    display: flex;

    // background-color: $primary;
    // border-radius: 4px;
    .MuiSvgIcon-root {
      color: white !important;
      padding: 1px 1px;
      font-size: 15px;
      margin: 0px 3px;
      background-color: $primary;
      border-radius: 4px;

      &:hover {
        color: $secondary;
      }
    }
  }

  tr.MuiTableRow-root:hover {
    background: var(--hover_background_color);
    // box-shadow: 1px 1px 11px 3px rgba(0, 0, 0, 0.3);
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3);

    & td.MuiTableCell-root.MuiTableCell-body {
      color: $primary;
    }

    & .MuiSvgIcon-root {
      // color: white;
    }
  }
}

.MuiDialogContent-dividers {
  .MuiTableBody-root {
    .MuiTableRow-root {
      &:hover {
        background: #e0e0e0 !important;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.3) !important;
      }

      // for conflict make a div and put
      .MuiTableCell-root {
        padding: 6px 14px !important;
        border-right: 1px solid #e0e0e0 !important;
        position: relative;

        &.null-text {
          font-style: italic;
        }

        @media screen and (max-width: 767px) {
          padding: 6px 34px !important;
        }

        button.MuiButtonBase-root.MuiIconButton-root {
          position: absolute;
          right: 0;
          top: -6px;

          @media screen and (max-width: 767px) {
            right: -10px;
          }

          svg.MuiSvgIcon-root {
            font-size: 16px;
            color: white;
            background-color: #0078d4;
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.pagination-cover {
  padding: 7px 14px;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  box-shadow: 1px 1px 11px 3px rgba(0, 0, 0, 0.3);
  flex-wrap: wrap;
  background: var(--background-color);

  @media screen and (max-width: 800px) {
    flex-direction: column;
    overflow: hidden;
  }

  @media screen and (max-width: 767px) {
    justify-content: center;
    padding: 10px;
  }

  .total-result {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      margin-bottom: 15px;
    }

    .total-size {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    p {
      margin: 0px;
      margin-right: 20px;
      font-size: var(--font-text-size) !important;
      // color: $primary;
      color: var(--font-color);
      font-weight: 500;

      @media screen and (max-width: 800px) {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }

    .pagination {
      list-style: none;
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0 0 0 20px;
      border: 0px solid $primary;
      border-radius: 3px;

      .page-item {
        padding: 0px 8px;
        display: flex;
        align-items: center;

        span {
          font-size: 15px;
        }

        &:not(:last-child) {
          border-right: 1px solid #d8dbe0;
        }

        .page-link {
          // font-size: $font-text-size;
          // text-decoration: none;
          // color: #2b4d7c;
          display: block;
          padding: 0.5rem 0.75rem;
          margin-left: -1px;
          line-height: 5px;
          // color: $primary;
          color: var(--font-color);
          background-color: var(--background-color);
          border: 1px solid #dee2e6;
          font-size: var(--font-text-size);

          @media screen and (max-width: 480px) {
            padding: 0rem;
          }
        }

        &.active {
          background-color: $primary;

          .page-link {
            color: var(--font-color);
          }
        }

        &.disabled {
          .page-link {
            background-color: var(--background-color);
            opacity: 0.7;
          }
        }
      }
    }
  }
}

.filter-box {
  width: 100px;

  .MuiFormLabel-root {
    color: #2b4d7c;
  }

  .PrivateNotchedOutline-root-194 {
    border-color: rgb(43, 77, 124);
  }

  .PrivateNotchedOutline-legend-195 {
    width: 66px;
  }

  .MuiOutlinedInput-root {
    width: 100px;
  }

  .MuiOutlinedInput-input {
    padding: 6px 14px;
    font-size: var(--font-text-size) !important;
  }

  .MuiFormControl-root {
    margin: 0px;
  }
}