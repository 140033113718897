.dashboard-cover .Innerheading .Innerheading {
    padding: 10px 7px;
    display: initial;
}
.grid.clusterCompare {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .DropdownDiv {
        display: flex;
        justify-content: flex-start;
        gap: 30px;

        .clusterDropdownDiv {
            display: flex;
            gap: 40px;
            align-items: center;
            width: 45%;

            .DropDown {
                width: 80%;
            }
        }

        .nodeDropdownDiv {
            display: flex;
            gap: 40px;
            align-items: center;
            width: 45%;

            .DropDown {
                width: 80%;
            }
        }

        p.dropdownHeading {
            font-size: 12px;
            font-weight: 700;
        }
    }

    .compareBtn {
        padding-left: 80px;
    }
    .dx-button-content {
        padding: 6px 10px;
    }
    .dx-button-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
    }
}

.cluster-Compare {
    .SubHeader {
        .headDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }

        .arrowBack {
            cursor: pointer;
        }
    }
}