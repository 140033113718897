@import "../../../assets/themes/default/share.scss";
@import "../../../assets/sass/global-import";

:root {
  --font-text-size: 12px;

}

body {
  font-size: $schema-page-font-text-size;
  // color: $primary;
  color: var(--font-color);
  font-family: $font-family;
}

input,
strong,
label,
span,
div,
textarea,
button,
body,
li,
a,
b,
hr,
br,
h1,
h2,
h3,
h4,
h5,
h6,
legend,
nav,
option,
p,
select,
table,
td,
tr,
tbody,
thead {
  font-family: $font-family;
}

.treeicon {
  width: 18px;
  height: 18px;
  max-width: 18px;
}

.schemamain {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    display: block;
  }

  //flex-flow: row wrap;
  .resizable-fragment {
    // min-width: 475px;
  }

  .schematree {
    width: 100%;
    float: left;
    padding: 0px 0px 0px 25px;
    border-right: 1px solid #ccc;
    max-height: calc(100vh - 150px);
    box-sizing: border-box;

    @media screen and (max-width: 767px) {
      width: 100%;
      float: none;
      padding: 0px 0px;
    }

    @media screen and (max-width: 1024px) and (min-width: 767px) {
      width: 100%;
      padding: 0px 0px;
      margin-bottom: 9px;
    }

    .MuiTypography-body1 {

      // font-size: $schema-page-font-text-size;
      @media screen and (max-width: 767px) {
        // font-size: $font-text-size;
      }
    }

    li.MuiTreeItem-root {
      // color: $primary;
      color: var(--font-color);
      padding: 2px 0px;

      .MuiTreeItem-content:hover {
        // background: $primary !important;
        background: var(--hover_background_color) !important;
        border-radius: 4px;
        //  color: $secondary !important;
        color: $font-color  !important;

        &:focus {
          background: $secondary  !important;
        }
      }

      .MuiTypography-body1 {
        font-size: var(--font-text-size);
        padding: 2px 5px;

        @media screen and (max-width: 767px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 131px;
          word-break: break-all;
        }
      }
    }

    span {
      display: flex;
      // align-items: center;
      position: relative;

      .MuiIconButton-root {
        padding: 0px !important;

        &:focus {
          outline: 0px auto -webkit-focus-ring-color;
        }

        .MuiSvgIcon-root {
          padding: 0px;

          @media screen and (max-width: 767px) {
            padding-left: 10px;
          }

          width: 15px;
          color: var(--font-color);
        }
      }
    }

    span.treeParentClass {
      left: -27px;

      @media screen and (max-width: 767px) {
        left: -2px;
      }

      .treeChildClass {
        color: var(--font-color);
        width: 100%;
        margin-left: 0% !important;

        .MuiTypography-body1 {
          margin: 0px 0px;
          white-space: nowrap;
          text-overflow: ellipsis;
          //max-width: 100px;
          overflow: hidden;
        }

        .MuiTreeItem-content {
          .MuiTreeItem-iconContainer {
            display: none !important;
          }
        }

        &.selected-child {
          .MuiTreeItem-label {
            background-color: rgba(0, 120, 212, 0.08) !important;
            border-left: solid #044c92 2px;
          }
        }
      }

      .childItemMenu {

        // left: 165px;
        @media screen and (max-width: 767px) {
          //  left: 70px;
        }
      }

      .MuiTreeItem-iconContainer {
        @media screen and (max-width: 767px) {
          width: 26px;
          display: flex;
          margin-right: 2px;
          justify-content: flex-end;
        }
      }

      .MuiTreeItem-iconContainer {
        display: block !important;

        .MuiSvgIcon-root {
          font-size: 18px;
        }
      }

      .MuiTreeItem-content {
        @media screen and (max-width: 767px) {
          width: 66%;
        }
      }

      &.selected {
        .MuiTreeItem-root {

          &.Mui-expanded {
            .MuiTreeItem-content {

              .MuiTreeItem-label {
                background-color: rgba(0, 120, 212, 0.08) !important;
              }
            }
          }

          &.treeChildClass {
            .MuiTreeItem-content {
              .MuiTreeItem-iconContainer {
                display: none !important;
              }

              .MuiTreeItem-label {

                background-color: transparent !important;

                &:hover {
                  background-color: var(--hover_background_color);
                  // background-color: #034c92 !important;
                  // color: #fff;
                  color: $font-color;
                  border-radius: 4px;
                }
              }
            }

            &.selected-child {
              .MuiTreeItem-label {
                background-color: rgba(0, 120, 212, 0.08) !important;
                border-left: solid $font-color 2px;
              }
            }
          }
        }
      }
    }

    .addschemaicon {
      cursor: pointer;

      .MuiSvgIcon-root {
        height: 0.7em;
      }
    }
  }

  form.schema-form {


    @media screen and (max-width: 1300px) and (min-width: 1024px) {
      width: 100%;
    }

    .schema-heading {
      border-bottom: 1px solid #d6d6d6;
      // background: $primary;
      background: var(--table_header_color);
      padding: 5px 10px;
      // color: #fff;
      color: var(--font-color);
      display: flex;
      justify-content: space-between;
      text-align: center;
      align-items: center;

      @media screen and (max-width: 767px) {
        padding: 5px 5px;
      }

      // @media screen and (max-width: 450px) {
      //  overflow-x: scroll;
      //  @include custom-scroller("x");
      // }

      strong {
        font-size: var(--font-text-size) !important;
        width: auto;

        @media screen and (max-width: 767px) {
          // font-size: $schema-page-font-text-size;
        }

        &:hover {
          background: #fff;
          color: #044c92;
          border-radius: 100px;
          width: 15px;
        }
      }
    }

    .schema-details {
      width: 40%;
      float: left;
      padding: 0px 0px;
      border: 1px solid $primary;
      margin: 0 auto;

      @media screen and (max-width: 767px) {
        width: 65%;
        padding: 0px 0px;
        margin: 0px;
      }



      @media screen and (max-width: 1300px) and (min-width: 1024px) {
        width: 80%;
      }

      .schemacontent {
        border-left: 0px solid #ccc;
        flex: 2 1;
        margin: 20px 10px;



        h3 {
          text-align: center;
          margin: 28px 0px;
          // font-size: 20px;
        }

        .hide {
          display: none;
        }

        .schemadiv {
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: center;
          padding: 0px 7px;

          @media screen and (max-width: 767px) {
            padding: 0px 3px;
          }

          label {
            //font-size: $schema-page-font-text-size;
            color: $primary;
            font-size: var(--font-text-size) !important;

            @media screen and (max-width: 767px) {
              //font-size: $schema-page-font-text-size;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 70px;
              margin: 0px;
            }
          }

          select.form-control {

            // font-size: $schema-page-font-text-size;
            @media screen and (max-width: 767px) {
              // font-size: $schema-page-font-text-size;
            }
          }

          input.form-control {

            //font-size: $schema-page-font-text-size;
            @media screen and (max-width: 767px) {
              // font-size: $schema-page-font-text-size;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 84px;
              margin: 0px;
            }
          }

          button.btn-primary {
            padding: 1px 4px;
            // font-size: $schema-page-font-text-size;
            margin-top: -30px;
          }

          .schemadivlabel {
            .form-group {

              // font-size: $schema-page-font-text-size;
              color: $primary;
              margin: 3px;

              label.choices {
                color: #000 !important;

                // font-size: 12px !important;
                @media screen and (max-width: 767px) {
                  // font-size: 11px !important;
                }
              }

              span {
                strong {
                  font-weight: 500;
                }
              }
            }
          }

          &.choices,
          .schemadiv {
            border-bottom: 1px solid $primary;
            margin: 10px 10px;
            padding: 0px;

            span {
              //  font-size: $schema-page-font-text-size;
              color: #000 !important;
            }
          }
        }

        th.MuiTableCell-root.MuiTableCell-head {
          padding: 2px 4px;
          // font-size: $schema-page-font-text-size;
          color: var(--font-color);
        }

        .MuiTableBody-root {
          td.MuiTableCell-root.MuiTableCell-body {
            // font-size: $schema-page-font-text-size;
            color: $primary  !important;
          }
        }
      }

      .schemacont {
        padding: 0px 0px;
        border: 1px solid $primary;
        margin: 0px 10px;
        min-height: 75px;

        &.etiddata {
          min-height: 0px;
          height: 300px;
        }
      }
    }

    .MuiButton-text {
      padding: 6px 8px;
      background: $primary;
      color: #fff;

      // font-size: $schema-page-font-text-size;
      &:hover {
        background: $primary;
      }
    }

    .save {
      text-align: right;
    }
  }

  .schema-details.hide {
    display: none;
  }

  .schemacontent.hide {
    display: none;
  }

  .hide {
    display: none;
  }

  .appBar {
    position: relative;
  }

  .appBartitle {
    margin-left: 2px;
    flex: 1px;
  }

  select.version-dropdown {
    width: 60px;
    color: $font-color;
    margin: 0px 13px;
    font-size: var(--font-text-size);

    @media screen and (max-width: 767px) {
      width: 40px;
    }
  }

  .tab-content {
    width: 100%;
    height: 110%;

    .MuiTableContainer-root {
      @include custom-scroller("xy");

      @media screen and (max-width: 767px) {
        overflow-x: unset;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 767px) {
      width: 100%;
    }

    @media screen and (max-width: 1300px) and (min-width: 1024px) {
      width: 100%;
    }
  }

  // .MuiTabs-flexContainer {
  //   background: $primary;
  // }

  .schemaedit {
    @media screen and (max-width: 1024px) and (min-width: 767px) {
      width: 100%;
      margin: 0px;
    }

    @media screen and (max-width: 1300px) and (min-width: 1024px) {
      width: 100%;
    }

    .schemadetails {
      .schematablecontent {
        display: flex;
        justify-content: space-between;
        border: 1px solid #ccc;
        border-bottom: 0px;
        padding: 0px;

        @media screen and (max-width: 767px) {
          flex-flow: row wrap;
        }

        .schematablecontenttype {
          width: 50%;
          float: left;
          padding: 0px 50px 6px 30px;

          @media screen and (max-width: 767px) {
            width: 100%;
            float: left;
            padding: 0px 0px 6px 0px;
          }

          @media screen and (max-width: 1024px) and (min-width: 767px) {
            width: 55%;
            float: left;
            padding: 0px 30px 6px 15px;
          }

          .schemacontent {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @media screen and (max-width: 767px) {
              flex-flow: row wrap;
              padding: 5px;
            }

            @media screen and (max-width: 1024px) and (min-width: 768px) {
              flex-flow: row wrap;
              padding: 5px;
            }

            strong {
              width: 35%;

              @media screen and (max-width: 767px) {
                width: 45%;
              }
            }

            p {
              width: 75%;
              margin-bottom: 10px;

              @media screen and (max-width: 767px) {
                margin-bottom: 5px;
                margin-top: 5px;
                width: 97%;
              }

              input[type="number"] {
                font-size: var(--font-text-size) !important;
                word-spacing: normal;
                text-overflow: ellipsis;
                padding: 4px 4px;
                border-radius: 0.25rem;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                width: 98%;
              }

              span {
                margin-right: 33px;
                padding-right: 12px;
                font-size: var(--font-text-size) !important;

                @media screen and (max-width: 767px) {
                  margin-right: 0px;
                }
              }
            }

            & input[type="text"] {
              font-size: var(--font-text-size) !important;
              word-spacing: normal;
              text-overflow: ellipsis;
              padding: 4px 4px;
              border-radius: 0.25rem;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid #ced4da;
              width: 98%;
            }
          }
        }
      }

      .top-description {
        .schemacontent {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width: 767px) {
            flex-flow: row wrap;
          }

          strong {
            font-size: var(--font-text-size) !important;
            width: 18%;
          }

          p {
            width: 100%;
          }

          textarea {
            width: 100%;
            max-height: calc(27vh - 70px);
            @include custom-scroller("y");
            border-radius: 3px;

            &:focus {
              outline: none;
            }
          }
        }
      }

      .schematablelist {

        .sysFields {
          pointer-events: none;
          cursor: default;
          color: var(--font-color);
          opacity: 0.5;
        }

        border: 1px solid #ccc;
        padding: 0px 0px;
        border-top: 0px solid #ccc;

        button.btn.btn-primary {
          padding: 4px 22px;
          margin: 7px 7px;
          float: right;
        }

        table.MuiTable-root {
          thead.MuiTableHead-root {
            tr.MuiTableRow-root.MuiTableRow-head {
              th.MuiTableCell-root.MuiTableCell-head {
                padding: 0px 10px;
                // color: $secondary;
                color: var(--font-color);
                font-size: var(--font-text-size) !important;
              }
            }
          }
        }

        td.MuiTableCell-root.MuiTableCell-body {
          font-size: var(--font-text-size) !important;
          color: var(--font-color);

          .MuiTableCell-head:last-child {
            text-align: center;
          }
        }

        svg.MuiSvgIcon-root {
          font-size: 15px;
          margin: 0px 3px;
        }

        .MuiTabs-root {
          min-height: 27px;

          .MuiTab-root {
            min-height: 30px;

            span.MuiTab-wrapper {
              font-size: var(--font-text-size) !important;
            }
          }
        }

        .schema-detail-field {
          border: 1px solid #ccc;
          margin: 25px 11px;
          padding: 10px;
          min-height: 27vh;
          box-shadow: 2px 1px 10px 3px rgba(0, 0, 0, 0.3);
          max-height: calc(27vh - 70px);
          @include custom-scroller("y");

          @media screen and (max-width: 767px) {
            margin: 10px 0px;
            min-height: 24vh;
            width: 93%;
          }

          @media screen and (max-width: 1024px) and (min-width: 767px) {
            min-height: 20vh;
            max-height: calc(27vh - 25px);
          }

          @media screen and (max-width: 1300px) and (min-width: 1024px) {
            min-height: 15vh;
            max-height: calc(27vh - 25px);
          }

          span {
            font-size: var(--font-text-size) !important;
          }

          .heading {

            border-bottom: 1px solid #ccc;
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            padding-bottom: 10px;

            h4 {
              margin: 0px;
              font-size: 15px;
              padding-bottom: 5px;
              // color: #044c92;
              color: $font-color;
              font-weight: 700;
            }

            .btn-primary {
              background-color: var(--background-color);

              color: #fff;
              padding: 0px 8px;
              border: none;
              height: 18px;
              border-radius: 5px;
              font-size: var(--font-text-size) !important;

              &:focus {
                outline-color: #044c92 !important;
              }
            }
          }

          .schema-edit-field {
            .schema-edit {
              display: flex;
              justify-content: space-between;
              width: 51%;
              margin: 7px 0px;

              @media screen and (max-width: 767px) {
                width: 100%;
              }

              span {
                margin: 0px 5px;

                input[type="text"] {
                  padding: 4px 4px;
                  border-radius: 0.25rem;
                  background-color: #fff;
                  background-clip: padding-box;
                  border: 1px solid #ced4da;
                  width: 95%;
                  font-size: var(--font-text-size) !important;
                }

                input[type="number"] {
                  font-size: var(--font-text-size) !important;
                  word-spacing: normal;
                  text-overflow: ellipsis;
                  padding: 5px 4px;
                  background-color: #fff;
                  background-clip: padding-box;
                  border: 1px solid #ced4da;
                  width: 95%;
                  border-radius: 0.25rem;
                }

                & select.form-control {
                  font-size: var(--font-text-size) !important; //11px;
                  height: 25px;
                  padding: 0px;
                  width: 98%;
                  background-color: #fff;
                  background-clip: padding-box;
                  border: 1px solid #ced4da;
                  border-radius: 0.25rem;
                }

                .alert.alert-danger.my-3 {
                  margin: 5px 0px !important;
                  padding: 2px 5px;
                  width: 98%;
                  font-size: var(--font-text-size) !important;
                }
              }

              &.checks {
                display: flex;
                justify-content: flex-start;
                width: 90%;

                span {
                  width: auto;
                }
              }

              .addchoice {
                margin: 10px 0px;
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;

                span {
                  input[type="text"] {
                    padding: 4px 5px;
                    margin: 4px 0px;
                    width: 100%;
                  }
                }

                .btn-primary {
                  margin: 0px 0px;
                }
              }
            }

            .schemabtn {
              margin: 0px 0px;
              padding: 0px 0px;
              width: 100%;
              float: right;

              @media screen and (max-width: 767px) {
                display: flex;
              }
            }
          }

          .schema-edit-fields {
            .schema-edits {
              width: 100%;
              float: right;
              max-height: calc(27vh - 70px);
              @include custom-scroller("y");

              .schema-adds {
                display: flex;
                justify-content: space-between;
                align-items: center;
                text-align: center;

                span {
                  .btn-primary {
                    margin: 0px 5px;
                    padding: 3px 24px;
                  }
                }
              }

              .add-schema-list {
                width: 100%;
                float: left;
                padding: 0px 0px;

                .schema-choices {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 95%;

                  .schema-values {
                    margin: 5px 15px;

                    input[type="text"] {
                      font-size: var(--font-text-size) !important;
                      word-spacing: normal;
                      text-overflow: ellipsis;
                      padding: 4px 5px;
                      border-radius: 0.25rem;
                      background-color: #fff;
                      background-clip: padding-box;
                      border: 1px solid #ced4da;
                      width: 100%;
                    }

                    input.form-control {
                      font-size: var(--font-text-size) !important;
                      word-spacing: normal;
                      text-overflow: ellipsis;
                      padding: 4px 4px;
                      border-radius: 0.25rem;
                      background-color: #fff;
                      background-clip: padding-box;
                      border: 1px solid #ced4da;
                      width: 100%;
                    }

                    select.form-control {
                      font-size: var(--font-text-size) !important;
                      padding: 3px 25px;
                      margin: 0px 11px;
                      border-radius: 4px;
                      border: 1px solid #ccc;
                    }
                  }

                  button.btn-primary {
                    font-size: var(--font-text-size) !important;
                    padding: 0px 6px;
                    margin: 11px 0px;
                    height: 16px;
                  }
                }
              }
            }
          }
        }

        .schemabtn {
          @media screen and (max-width: 767px) {
            display: flex;
          }
        }
      }
    }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        margin: 0px;
        font-size: 15px;
      }

      button.btn-primary {
        margin: 0px 0px;
        padding: 0px 5px;

        &:hover {
          border: 1px solid #e9ecef !important;
          color: #044c92 !important;
          background: #fff !important;
          transition: 0.5s;
        }
      }
    }

    .schema-edit-field {
      .schema-edit {
        width: 50%;
        float: left;

        span {
          width: 100%;
          float: left;
        }

        &.checks {
          display: flex;
          justify-content: flex-start;
          width: 100%;

          span {
            width: auto;
          }
        }
      }

      .schema-edit-fields {
        display: grid;
        width: 48%;
        border-left: 1px solid #ccc;

        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }

  .schemas {
    border: 1px solid #e2e2e2;
    min-height: 27vh;
    box-shadow: 1px 1px 11px 3px rgba(0, 0, 0, 0.3);
    margin: 20px 6px;
    padding: 0px 5px;

    .heading {
      border-bottom: 1px solid #ccc;
      padding: 5px 0px;
    }

    .schema-edit-index {
      display: flex;
      justify-content: flex-start;
      padding: 7px 7px;

      @media screen and (max-width: 767px) {
        flex-flow: row wrap;
      }

      .edit-schemas {
        width: 50%;

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        .schema-index {
          width: 100%;
          float: left;
          display: flex;
          justify-content: flex-start;
          padding: 0px;
          align-items: center;

          strong {
            @media screen and (max-width: 767px) {
              width: 76%;
            }
          }

          span {
            width: 70%;
            float: right;
            padding: 5px 10px;

            font-size: var(--font-text-size) !important;

            input[type="text"] {
              font-size: var(--font-text-size) !important;
              word-spacing: normal;
              text-overflow: ellipsis;
              padding: 4px 4px;
              border-radius: 0.25rem;
              background-color: #fff;
              background-clip: padding-box;
              border: 1px solid #ced4da;
              width: 95%;
              // height: 30px;
            }
          }

          & .check {
            float: right;
            width: 70%;
            display: flex;
            justify-content: flex-start;

            span {
              width: 32% !important;
            }
          }
        }
      }

      .schemafields {
        width: 50%;
        padding: 0px 15px;
        border-left: 1px solid #ccc;
        max-height: calc(23vh - 65px);
        @include custom-scroller("y");

        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 0px 0px;
        }

        .fileds-list {
          display: flex;
          align-items: center;
          margin-bottom: 7px;

          span {
            width: 100%;

            select.form-control {
              font-size: var(--font-text-size) !important;
              width: 89%;
              padding: 2px 3px;
              border-radius: 3px;
              border: 1px solid #ccc;
            }
          }
        }

        .schema-edits {
          float: right;
          width: 97%;

          .exitinf-fields {
            display: flex;
            justify-content: space-between;
            margin: 5px 0px;

            span {
              width: 92%;

              input.form-control {
                width: 71%;
                float: right;
                font-size: var(--font-text-size) !important; //12px;
                background: #fff;
                border: 1px solid #ced4da;
                border-radius: 4px;
                padding: 3px 5px;
              }
            }

            button.btn-primary {
              padding: 0px 5px;
              margin: 5px 0px;
              // height: 20px;
            }
          }
        }
      }

      .edit-schema-fields {
        width: 50%;
        padding: 0px 15px;
        border-left: 1px solid #ccc;

        span {
          float: right;
          width: 80%;

          select.form-control {
            font-size: var(--font-text-size) !important;
            word-spacing: normal;
            text-overflow: ellipsis;
            padding: 4px 4px;
            border-radius: 0.25rem;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            height: 30px;

            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }
}

strong {
  font-size: var(--font-text-size) !important;
  width: 35%;

  @media screen and (max-width: 767px) {
    width: 55%;
  }
}

strong.choice-item {
  text-align: left;
  padding: 0px 16px;
}

.checks {
  display: flex;
  justify-content: flex-start;
  width: 100%;

  span {
    width: auto !important;
  }
}

//  .check {
//   float: right;
//   width: 70%;
//   display: flex;
//   justify-content: flex-start;
//           span{
//             width: 22% !important;
//           }
//  }

.schema-heading {
  border-bottom: 1px solid #d6d6d6;
  // background: $primary;
  background: var(--table_header_color);
  padding: 5px 10px;
  color: var(--font-color);
  // color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    padding: 5px 5px;
  }

  span {
    font-size: var(--font-text-size) !important;
    font-family: $font-family;
    font-weight: 600;
    text-transform: capitalize;

    @media screen and (max-width: 767px) {
      font-size: 10px !important;
    }
  }

  .schema-dropdown {
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  strong {
    font-size: var(--font-text-size) !important;
    width: auto;

    @media screen and (max-width: 767px) {
      // font-size: $schema-page-font-text-size;
    }

    &:hover {
      background: #fff;
      color: #044c92;
      border-radius: 100px;
    }

    svg.MuiSvgIcon-root {
      width: 15px;
      height: 15px;
      vertical-align: sub;
    }
  }
}

.schema-details {
  // width: 100%;
  // float: left;
  padding: 0px 0px;
  border: 1px solid #ccc;
  //margin: 0 auto;
  height: 100%;

  @media screen and (max-width: 767px) {
    padding: 0px 0px;
    // float: right;
    // margin: 0px;
  }

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 1300px) and (min-width: 1024px) {
    width: 100%;
  }

  .schemacontent {
    border-left: 0px solid #ccc;
    flex: 2 1;
    margin: 0px 0px;

    @media screen and (max-width: 767px) {
      overflow: scroll;
    }

    h3 {
      text-align: center;
      margin: 28px 0px;
      // font-size: 20px;
    }

    .hide {
      display: none;
    }

    .schemadiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      padding: 5px 7px;



      label {
        //font-size: $schema-page-font-text-size;
        color: $primary;
        font-size: var(--font-text-size) !important; //12px;
        font-family: $font-family;

        @media screen and (max-width: 767px) {
          //font-size: $schema-page-font-text-size;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 70px;
          margin: 0px;
        }
      }

      select.form-control {

        // font-size: $schema-page-font-text-size;
        @media screen and (max-width: 767px) {
          // font-size: $schema-page-font-text-size;
        }
      }

      input.form-control {

        //font-size: $schema-page-font-text-size;
        @media screen and (max-width: 767px) {
          // font-size: $schema-page-font-text-size;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 84px;
          margin: 0px;
        }
      }

      button.btn-primary {
        padding: 1px 4px;
        // font-size: $schema-page-font-text-size;
        margin-top: -30px;
      }

      .schemadivlabel {
        @media screen and (max-width: 767px) {
          width: 52%;
          float: left;
          text-align: left;
        }

        .form-group {

          // font-size: $schema-page-font-text-size;
          color: $primary;
          margin: 3px;

          label.choices {
            font-size: $font-text-size;
            color: #000 !important;

            // font-size: 12px !important;
            @media screen and (max-width: 767px) {
              // font-size: 11px !important;
            }
          }

          span {
            strong {

              font-size: var(--font-text-size) !important; //$strong-font-size;
              font-family: $font-family;
              font-weight: 600;
            }
          }
        }
      }

      &.choices,
      .schemadiv {
        border-bottom: 1px solid $primary;
        margin: 10px 10px;
        padding: 0px;

        span {
          //  font-size: $schema-page-font-text-size;
          color: #000 !important;
        }
      }
    }

    th.MuiTableCell-root.MuiTableCell-head {
      padding: 2px 4px;
      // font-size: $schema-page-font-text-size;
      // color: #fff;
      color: var(--font-color);
      font-size: var(--font-text-size) !important;
    }

    .MuiTableBody-root {
      td.MuiTableCell-root.MuiTableCell-body {
        // font-size: $schema-page-font-text-size;
        // color: $primary !important;
        color: var(--font-color) !important;
        font-size: var(--font-text-size) !important;
        border-left: 1px solid rgba(224, 224, 224, 1);
      }
    }
  }

  .schemacont {
    padding: 0px 0px;
    // border: 2px solid $primary;
    margin: 0px 0px;
    min-height: 75px;
    overflow-x: auto;
    overflow-y: auto;
    @include custom-scroller("xy");

    &.etiddata {
      // overflow-x: auto;
      // overflow-y: auto;
      overflow-x: scroll;
      overflow-y: scroll;
      min-width: 100%;
      width: 800px;
      max-height: 100%;
      height: calc(96vh - 373px);
      // border: 2px solid $primary;
      @include custom-scroller("xy");

      .table-sort {
        display: flex;
        text-align: left;
        align-items: center;
        text-transform: capitalize;
        // color: $secondary;
        color: var(--font-color);
        font-size: var(--font-text-size) !important;

        & .MuiTableCell-head {
          color: $primary;
        }

        .arrowicon {
          max-height: 14px;
          color: #0078d4;

          &:hover {
            color: $primary;
          }
        }

        .MuiTableCell-head:last-child {
          float: right;
          border-bottom: 0px solid rgba(224, 224, 224, 1) !important;
        }

        .sort-icons {
          display: flex;
          cursor: pointer;

          .icon-asc {
            margin: 0px;
            padding: 0px;
            max-height: 100px;
            height: $font-text-size;
            margin-right: -2px;

            &:hover {
              background: #ffffff80;
              border-radius: 100px;
            }
          }

          .icon-desc {
            margin: 0px;
            padding: 0px;
            max-height: 100px;
            height: $font-text-size;

            &:hover {
              background: #ffffff80;
              border-radius: 100px;
            }
          }
        }
      }

      .MuiTableCell-root {
        &.etid-null-text {
          font-style: italic;
        }
      }

      .action-icon {
        float: left;
        align-items: center;
        text-align: center;
        display: flex;
      }
    }

    &.etidfiled {
      overflow-x: auto;
      overflow-y: auto;
      max-height: 100%;
      height: calc(100vh - 403px);
      @include custom-scroller("xy");

      .MuiTableCell-root {
        &.etid-null-text {
          font-style: italic;
        }

        &.system-fields {
          font-style: italic;
          font-weight: 600;
        }
      }

      .action-icon {
        float: left;
        align-items: center;
        text-align: center;
        display: flex;
      }
    }

    @media screen and (max-width: 767px) {
      border: 0px solid $primary;
    }
  }
}

.schema-details-unavailable {
  float: left;
  padding: 0px 0px;
  margin: 20px auto;
  height: 100%;
}

.MuiButton-text {
  padding: 6px 8px;
  background: $primary;
  color: #fff;

  // font-size: $schema-page-font-text-size;
  &:hover {
    background: $primary;
  }
}

.menupopup {
  .MuiDialog-paperWidthSm {
    width: 200px;
    background: var(--background-color);
    color: var(--font-color);

    .MuiListItemAvatar-root {
      min-width: 35px;

      flex-shrink: 0;

      .MuiAvatar-colorDefault {
        color: $secondary;
        background: $primary;
        width: 23px;
        height: 23px;

        svg.MuiSvgIcon-root {
          width: 13px;
        }
      }
    }

    .MuiTypography-h6 {
      font-size: 13px;
      font-weight: 600;
    }

    .MuiList-padding {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      border-top: 1px solid #ccc;

      .MuiListItem-gutters {
        padding: 5px 10px;
      }
    }
  }

  .MuiListItemText-root {
    .MuiTypography-body1 {
      font-size: var(--font-text-size) !important;
      font-weight: 100;
    }
  }
}

.modalpopoup.audit {
  .MuiDialogTitle-root {
    border-bottom: 1px solid var(--table_header_color);
  }

  .MuiTypography-body1 {
    font-size: 14px;
    font-weight: 600;
    text-decoration: underline;

    &.timeline-typo {
      margin-top: 15px;
    }
  }

  .status-block {
    display: flex;
    justify-content: space-between;

    .status-section {
      display: flex;
      align-items: center;

      .MuiTypography-body2 {
        font-size: 14px;
        font-weight: 600;
        padding-left: 5px;

        span {
          font-weight: 400;
          text-transform: capitalize;
        }
      }
    }

    .MuiIconButton-root {
      padding: 2px;
    }
  }

  .MuiTableContainer-root {
    border: 1px solid var(--table_header_color);
  }

  .schemacont {
    padding: 0px 0px;
    margin: 0px 0px;

    .MuiTable-root {
      .MuiTableHead-root {
        .MuiTableCell-root {
          font-size: 13px;
          color: var(--font-color);
        }
      }
    }

    &.comp-data {
      height: 270px;
      overflow-x: auto;
      overflow-y: auto;
      // border: 2px solid $primary;
      @include custom-scroller("xy");

      .MuiTableCell-root {
        &.audit-null-text {
          font-style: italic;
        }

        &:first-child {
          font-weight: 600;
          color: var(--font-color);
        }
      }

      .Check-icon {
        color: green;
      }

      .Cross-icon {
        color: red;
      }
    }

    &.audit-data {
      height: 270px;
      overflow-x: auto;
      overflow-y: auto;
      // border: 2px solid $primary;
      @include custom-scroller("xy");

      .MuiTableCell-root {
        &.audit-null-text {
          font-style: italic;
        }

        &:first-child {
          font-weight: 600;
          color: var(--font-color);
        }
      }
    }
  }

  .action-icon {
    float: left;
    align-items: center;
    text-align: center;
    display: flex;
  }

  .MuiDialogActions-root {
    padding: 5px 35px;
  }
}

button#simple-tab-0 span {
  text-transform: capitalize;
}

button#simple-tab-1 span {
  text-transform: capitalize;
}

button#simple-tab-2 span {
  text-transform: capitalize;
}

.editmenulist {
  .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    margin-top: 0px !important;
  }

  li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
    padding: 0px 0px !important;

    .btn.btn-primary {
      padding: 9px 23px !important;

      &:hover {
        background-color: $primary;
        border: $primary;
      }

      @media screen and (max-width: 767px) {
        padding: 9px !important;
      }
    }
  }

  .MuiListItem-gutters {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  div[class^="MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded"] {
    width: 4%;

    @media screen and (max-width: 767px) {
      width: 10%;
    }
  }
}

.MuiListItem-gutters {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

li.MuiTreeItem-root.active {
  background: $primary;
  // color: $secondary !important;
  border-radius: 4px;
}

.MuiTab-root {
  min-height: 30px !important;
}

.MuiTabs-root {
  min-height: 30px !important;
  background: var(--background-color);
  color: var(--font-color);
}

button#simple-tab-0 {
  min-height: 30px;
}

.cellboarder {
  border-right: 1px solid #ccc;
  border-bottom: 0px solid #000 !important;
}

.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.schemaedits {
  margin: 0 auto;
  width: 100%;

  @media screen and (max-width: 767px) {
    overflow: scroll;
  }

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    width: 100%;
  }

  @media screen and (max-width: 1300px) and (min-width: 1024px) {
    width: 100%;
  }
}

.schemaviewdefault {
  margin: 9px 0px;
  font-family: $font-family;
  color: var(--font-color);

  .schemadivlabel.center {
    margin: 0px 30px;

    @media screen and (max-width: 767px) {
      margin: 0px 0px;
    }
  }

  label {
    font-size: var(--font-text-size) !important;
    font-family: $font-family;

    @media screen and (max-width: 767px) {
      word-break: break-all;
    }
  }

  .MuiGrid-spacing-xs-3>.MuiGrid-item {
    padding: 3px 12px;

    @media screen and (max-width: 767px) {
      padding: 9px 15px;
    }
  }
}

.not-found {
  // color: $primary;
  color: $font-color;
}

.viewtables {
  .MuiAppBar-colorPrimary {
    width: 320px;

    @media screen and (max-width: 767px) {
      width: 144px;
    }
  }

  .tab-content {
    margin-top: -36px;

    @media screen and (max-width: 767px) {
      margin-top: 0px;
    }
  }
}

span.treeParentClass {
  .MuiTreeItem-root:focus>.MuiTreeItem-content {
    background-color: var(--selected_background_color) !important;
  }
}

.alert.alert-danger {
  color: red;
  font-size: var(--font-text-size) !important;
}

.resizable-fragment {
  &:first-child {
    @media screen and (max-width: 767px) {
      width: 75% !important;
    }
  }
}

.pagination-cover {
  @media screen and (max-width: 767px) {
    overflow-x: auto !important;
    @include custom-scroller("x");
    float: left !important;

    .page-link {
      line-height: 12px !important;
    }
  }
}

.schemacontent {
  .schematablelist {
    @media screen and (max-width: 767px) {
      float: left;
    }
  }
}

.pagination-cover .filter-box .MuiFormLabel-root {
  &:first-child {
    color: var(--font-color);
  }
}

.btn-primary {
  &:focus {
    outline-color: $primary  !important;
  }
}

.schematree.sidebar.withMargin.panel {
  background: var(--background-color) !important;
}

.PrivateTabIndicator-colorSecondary-281 {
  &:last-child {
    background: $primary  !important;
  }
}

.MuiAppBar-colorPrimary {
  &:first-child {
    background: var(--background-color);
    color: var(--font-color);
    border: 0.5px solid var(--border_color);
  }
}

.MuiTableRow-head {
  &:last-child {
    background: var(--table_header_color) !important;
  }
}

.MuiTabs-indicator {
  background: $primary  !important;
}

.orgddl {
  font-size: var(--font-text-size) !important;
  word-spacing: normal;
  text-overflow: ellipsis;
  padding: 4px 4px;
  border-radius: 0.25rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  width: 101%;
}

.treeitemwidth {
  width: 100%;
}

.searchBarWidth {
  padding: 7px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    font-weight: bold;
    font-size: 12px;
  }

  input {
    font-size: 13px;
  }

  svg.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }

  .MuiPaper-root {
    height: 33px;
  }
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded {
  background: var(--background-color);
  color: var(--font-color);
}

.resizablefragmentdiv {
  max-width: 502px;
  //min-width: 21%;
}

.schemadetailsdiv {
  margin-left: 0.5%;
  width: 100%;
  // max-width: 80%;
  // min-width: 78%;
  height: calc(100vh - 150px);
}

span.MuiTab-wrapper {
  font-size: var(--font-text-size);
}

// .MuiTableRow-root:nth-last-child(1) {
//   border-bottom: 2px solid $primary;
// }

.MuiTableCell-head {
  // border-left: 1px solid #e0e0e0;
}

.Mui-selected {
  background-color: var(--selected_background_color) !important;
}

li.MuiTreeItem-root.treeitemwidth.Mui-expanded.Mui-selected {
  background: transparent !important;
}

span.logo1 img {
  height: 21px;
}

.MuiDialog-paper {
  overflow-y: hidden !important;
}

.MuiDialogContent-root::-webkit-scrollbar {
  width: 10px;
}

.MuiDialogContent-root::-webkit-scrollbar-thumb {
  border-radius: 20px 20px;
  background-color: #0078d4;
  opacity: 0.5;
  width: 10px;

  &:hover {
    opacity: 1;
  }
}