@import "../../../assets/themes/default/share.scss";
@import "../../../assets/sass/global-import";
:root {
  --font-text-size: 12px;
}
.MuiDialog-paperWidthSm {
  background: var(--background-color);
  color:  var(--font-color);
  .MuiDialogTitle-root {
    border-bottom: 1px solid #dee2e6;
    padding-left: 15px !important;
    h6.MuiTypography-root.MuiTypography-h6 {
      color: var(--font-color);
      font-size: 16px;
    }
    .MuiSvgIcon-root {
      font-size: 18px !important;
    }
  }
}

.content-boxs {
  margin-bottom: 10px;

  .change-password {
    width: 474px;
    padding: 0px 30px;
    @media screen and (max-width: 767px) {
      width: 80%;
    }
    .form-head {
      text-align: center;
    }
    .form-group {
      margin: 0px;
      .MuiFormControl-marginNormal {
        margin-top: 0px;
      }
    }
    .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
      width: 100%;
    }
    .MuiInputLabel-root {
      font-size: var(--font-text-size);
      color: var(--font-color);
    }
    .login-button {
      float: right;
      border-radius: 5px;
    }
  }
}
