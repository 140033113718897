@import "../../../../assets/themes/default/share.scss";
@import "../../../../assets/sass/styles.scss";

:root {
    --font-text-size: 12px;
    --background-color: #fff;
    --font-color: #000000;
    --border_color: #006eaf;
}

.pagination-cover {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    flex-wrap: wrap;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        overflow: hidden;
    }

    @media screen and (max-width:767px) {
        justify-content: center;
        padding: 10px;
    }

    .total-result {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media screen and (max-width: 800px) {
            flex-direction: column;
            margin-bottom: 15px;
        }

        p {
            margin: 0px;
            margin-right: 20px;
            font-size: 14px;
            color: #2b4d7c;
            font-weight: 500;

            @media screen and (max-width: 800px) {
                margin-right: 0px;
                margin-bottom: 10px;
            }
        }

        .pagination {
            list-style: none;
            display: flex;
            align-items: center;
            padding: 0;
            margin: 0 0 0 20px;
            border: 1px solid #2b4d7c;
            border-radius: 3px;

            .page-item {
                padding: 0px 8px;
                display: flex;
                align-items: center;

                span {
                    font-size: 15px;
                }

                &:not(:last-child) {
                    border-right: 1px solid #d8dbe0;
                }

                .page-link {
                    // font-size: $font-text-size;
                    // text-decoration: none;
                    // color: #2b4d7c;
                    display: block;
                    padding: 0.5rem 0.75rem;
                    margin-left: -1px;
                    line-height: 5px;
                    // color: $primary;
                    color: var(--font-color);
                    background-color: var(--background-color);
                    border: 1px solid #dee2e6;
                    font-size: var(--font-text-size);

                    @media screen and (max-width: 480px) {
                        padding: 0rem;
                    }
                }

                &.active {
                    background-color: $primary;

                    .page-link {
                        color: var(--font-color);
                    }
                }

                &.disabled {
                    .page-link {
                        background-color: var(--background-color);
                        opacity: 0.7;
                        pointer-events: none;
                    }
                }
            }
        }
    }

    .filter-box {
        width: 100px;

        .MuiFormLabel-root {
            color: #2b4d7c;
        }

        .PrivateNotchedOutline-root-194 {
            border-color: rgb(43, 77, 124);
        }

        .PrivateNotchedOutline-legend-195 {
            width: 66px;
        }

        .MuiOutlinedInput-root {
            width: 100px;
        }

        .MuiOutlinedInput-input {
            padding: 6px 14px;
            color: var(--font-color);
            font-size: var(--font-text-size);
        }

        .MuiFormControl-root {
            margin: 0px;
        }
    }
}


.filter-box {
    width: auto;

    .MuiFormLabel-root {
        color: #2b4d7c;
    }

    .PrivateNotchedOutline-root-194 {
        border-color: rgb(43, 77, 124);
    }

    .PrivateNotchedOutline-legend-195 {
        width: 66px;
    }

    .MuiOutlinedInput-root {
        width: auto;
    }

    .MuiOutlinedInput-input {
        padding: 6px 14px;
        color: var(--font-color);
        font-size: var(--font-text-size);
    }

    .MuiFormControl-root {
        margin: 0px;
    }
}