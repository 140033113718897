.Innerdetails {
    padding: 14px 6px;

    .cls {
        // padding-right:100px;
    }

    .grid {
        #grid-container {
            .dx-datagrid-headers.dx-datagrid-nowrap {
                background: #9b9595;
                color: black;
                /* font-size: 13px; */
                font-weight: 700;

            }

            .dx-master-detail-cell {
                padding: 0px;
            }

            #grid-container-nested {
                .dx-datagrid-headers.dx-datagrid-nowrap {
                    background: #d5c6c6;
                    color: black;
                    /* font-size: 13px; */
                    font-weight: 700;

                }

            }

            // .dx-master-detail-cell {
            //     padding: 0px !important;
            // }
        }
    }
}

.actionBtn {
    .dx-button .dx-button-content {
        padding: 6px !important;
        font-size: 11px !important;
    }

}

.status-column{
    display: flex;
    align-items: center;
    justify-content:flex-start;
    .status-column-circle{
        width: 12px;
        height: 12px;
        // margin: 4px 8px 4px 90px;
        margin-right: 4px;
        border-radius: 8px;
    }
    .yellow{
        background-color: #f7b500;
    }
    .green{
        background-color: #6dd400;
    }
    .status-column-text{
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.45;
        letter-spacing: normal;
        color: #495057;
    }
}