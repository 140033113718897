#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(255, 255, 255, 0.63);

  & #loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;

    @media screen and (max-width: 767px) {
      left: 62%;
    }
  }
}

.small-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // z-index: 99999;
  background-color: rgba(255, 255, 255, 0.63);
}

.MuiCircularProgress-root {
  display: flex;

  &.loader-icon {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}