@import "../../../assets/themes/default/share.scss";
@import "../../../assets/sass/global-import";

:root {
  --font-text-size: 12px;
}

.page-dashboard {
  .MuiPaper-root {
    background: var(--background-color);
  }

  tbody.MuiTableBody-root {
    background: var(--background-color);
  }

  //   padding: 10px 20px;
  //   @media screen and (max-width: 767px) {
  //     padding: 0px;
  //   }
  .heading {
    font-size: 16px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-top: 0px;
    margin-bottom: 5px;
    color: #2b4d7c;
  }

  .MuiGrid-root {
    @media screen and (max-width: 767px) {
      display: block;
    }

    .envelope-health {
      .refresh-title {
        text-align: center;

        p {
          font-size: var(--font-text-size);
          font-weight: bold;
          margin: 0;
        }
      }

      .loader-content {
        position: relative;
        padding: 10px;
      }

      .MuiGrid-spacing-xs-3 {
        padding: 5px 1px !important;

        .health-card {
          box-sizing: border-box;

          &.Good {
            background: var(--Good_color); // #d3efc3;
          }

          &.Pending {
            background: var(--Pending_color); // #fdf0c8;
          }

          &.Bad {
            background: var(--Bad_color); // #fdc7c5;
          }

          .health-header {
            display: flex;

            align-items: center !important;
            color: var(--font-color);

            padding: 15px;
            justify-content: space-between;
            border: none !important;
            // background: none !important;
            border-radius: none !important;

            .MuiCardHeader-content {
              .MuiCardHeader-title {
                text-transform: none;
                color: var(--font-color);
              }

              .MuiCardHeader-subheader {
                text-transform: none;
                color: var(--font-color);

              }
            }

            .MuiCardHeader-action {
              margin-right: 0px;

              svg.MuiSvgIcon-root {
                color: var(--font-color);
                font-size: 14px;
              }
            }
          }

          .MuiCardContent-root {
            box-sizing: border-box;
            border: none;
            padding: 0px 15px;
            margin-bottom: 10px;

            .content-block {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding-top: 5px;

              .content-label {
                width: 50%;
              }

              span {
                font-size: var(--font-text-size);
                width: 50%;

                &.span-content {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                &.field-span {
                  list-style: none !important;
                }

                &.field-span li:after {
                  content: ", ";
                }
              }
            }
          }
        }

        .MuiPaper-elevation1 {
          box-shadow: 2px 3px 10px 1px rgba(0, 0, 0, 0.3);

          &.Good {
            &:hover {
              box-shadow: 0px 0px 1px 1px #82B366;
            }
          }

          &.Pending {
            &:hover {
              box-shadow: 0px 0px 1px 1px #d6b656;
            }
          }

          &.Bad {
            &:hover {
              box-shadow: 0px 0px 1px 1px #b85450;
            }
          }
        }
      }
    }
  }

  .MuiCardHeader-content {
    flex: 1 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiPaper-elevation1 {
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  }

  .MuiCardHeader-avatar {
    flex: 0 0 auto;
    margin-right: 6px;
    margin-top: 3px;
  }

  .MuiTypography-displayBlock {
    display: block;
    text-transform: uppercase;
    font-size: 11px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .MuiCardHeader-root {
    display: flex;
    padding: 5px;
    align-items: center !important;
    border: 1px solid var(--border_color);
    // border: 0px solid #044c92;
    // background: #044c92;
    //background: var(--table_header_color);
    // color: #fff;
    color: var(--font-color);

    // box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    //   0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-radius: 10px 10px 0px 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    justify-content: space-between;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
  }

  .MuiIconButton-root {
    padding: 5px;
    color: rgb(255, 255, 255);
  }

  .MuiCardHeader-action {
    margin-top: 0px;
  }

  .MuiCard-root {
    width: 100%;
    margin: 12px 10px;
    margin-left: 0px;

    &:last-child {
      margin-right: 0px;
    }

    @media screen and (max-width: 767px) {
      margin: 15px 0px;
    }
  }

  .MuiAvatar-root {
    width: 17px;
    height: 17px;
    font-size: 13px;
  }

  .MuiCardContent-root {
    padding: 0px 0px;
    padding-bottom: 0px !important;
    border: 1px solid var(--border_color);
    width: 100%;
    // background: var(--background-color);
    color: var(--font-color);

    .MuiTableContainer-root {
      @media screen and (max-width: 767px) {
        overflow-x: unset;
      }

      @media screen and (max-width: 1024px) and (min-width: 768px) {
        overflow-x: unset;
      }

      @media screen and (max-width: 1300px) and (min-width: 1024px) {
        overflow-x: unset;
      }

    }

    .MuiTable-root {
      @media screen and (max-width: 767px) {
        overflow-y: scroll;
      }
    }

    #reactgooglegraph {

      padding: 0px 10px;

      text {
        font-size: var(--font-text-size);
      }
    }

    div#reactgooglegraph-2 {
      margin: 0px 10px;
    }

    .MuiTableRow-head {
      // background: #223e63;
      // color: #fff;
      background: var(--table_header_background) !important;
      color: var(--font-color) !important;

      .MuiTableCell-head {
        // color: #fff;
        color: var(--font-color);
        font-size: var(--font-text-size);
        text-align: left !important;

        @media screen and (max-width: 767px) {
          padding: 6px 15px;
        }
      }
    }

    .MuiTableCell-body {
      font-size: var(--font-text-size);
      border-left: 1px solid rgba(224, 224, 224, 1);
      // text-align: left !important;
      color: var(--font-color);
    }

    .datepicker {
      margin: 10px 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @media screen and (max-width: 767px) {
        flex-flow: row wrap;
        justify-content: end;
        display: block;
      }

      .react-datepicker-wrapper {
        margin: 10px 10px;
      }

      a {
        padding: 6px 24px;
        margin: 0px 12px;
        background: var(--btn_background_color);
        color: #fff;
        border: 1px solid var(--border_color);
        border-radius: 4px;
        font-family: $font-family;
        font-size: var(--font-text-size);
        cursor: pointer;
        font-weight: 600;

        @media screen and (max-width: 767px) {
          margin: 0px 0px;
        }
      }

      .react-datepicker__input-container {
        input[type="text"] {
          padding: 5px 6px;
          border: 1px solid var(--border_color);
          border-radius: 4px;

          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }

      .react-datepicker-popper {
        @media screen and (max-width: 767px) {
          left: -12% !important;
        }
      }
    }
  }

  .health-filter {
    padding: 0px 0px;
    padding-bottom: 0px !important;
    border: 1px solid var(--border_color);
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: var(--tab_background_color);
    color: var(--font-color);

    .filter-content {
      display: flex;

      @media screen and (max-width: 767px) {
        display: block;
      }
    }

    .datepicker {
      margin: 10px 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @media screen and (max-width: 767px) {
        flex-flow: row wrap;
        justify-content: flex-end;
        display: block;
      }

      .statuslabel {
        margin-right: 7px;
        font-size: var(--font-text-size);
        font-weight: bold;
      }

      .react-datepicker-wrapper {
        margin: 10px 10px;
      }

      select.form-control {
        -webkit-box-align: center;
        align-items: center;
        background-color: rgb(255, 255, 255);
        cursor: default;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        justify-content: space-between;
        min-height: 29px;
        position: relative;
        box-sizing: border-box;
        border-color: rgb(204, 204, 204);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        transition: all 100ms ease 0s;
        outline: 0px !important;
        padding: 5px 6px;
        font-size: var(--font-text-size);
      }

      a {
        padding: 6px 24px;
        margin: 0px 12px;
        background: $primary;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-family: $font-family;
        font-size: var(--font-text-size);
        cursor: pointer;
        font-weight: 600;

        @media screen and (max-width: 767px) {
          margin: 0px 0px;
        }
      }

      .react-datepicker__input-container {
        input[type="text"] {
          padding: 5px 6px;
          border: 1px solid var(--border_color);
          border-radius: 4px;

          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }

      .react-datepicker-popper {
        @media screen and (max-width: 767px) {
          left: -12% !important;
        }
      }
    }

    .filter-buttons {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .health-button {
        text-transform: capitalize;
        margin: 10px;
        font-weight: 600;
        border-radius: 50%;
        font-size: var(--font-text-size);
        box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
        background-color: var(--btn_background_color);
        border: 1px solid var(--border_color);
        color: var(--font-color);

        &:hover {
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        }

        // &:active {
        //   box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        //     0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        // }
      }
    }
  }

  .MuiCardHeader-root .MuiCardHeader-content .MuiCardHeader-title {
    // color: #fff;
    color: var(--font-color);
  }

  .MuiCardHeader-root .MuiCardHeader-action svg.MuiSvgIcon-root {
    color: #ffffff;
    font-size: 14px;
  }

  .not-found {
    text-align: center;
  }

  .not-found-health {
    text-align: center;
    border-radius: 4px;
    margin-top: 10px;
    font-size: 13px;
    font-weight: 600;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  }

  .MuiPaper-root.paging-tab.MuiPaper-elevation1 {
    margin: 0px 0px;

    .MuiTab-root {
      @media screen and (max-width: 767px) {
        max-width: 42%;
      }

      @media screen and (max-width: 1024px) and (min-width: 768px) {
        max-width: 42%;
      }
    }
  }
}

.customCol {
  width: 32%;
}

span.start-date {
  font-size: var(--font-text-size);
  font-family: $font-family;
  font-weight: 600;
  // color: #044c92;
  color: var(--font-color);
}

span.end-date {
  font-size: var(--font-text-size);
  font-family: $font-family;
  font-weight: 600;
  // color: #044c92;
  color: var(--font-color);
}

span.no-data {
  font-size: var(--font-text-size);
  font-family: $font-family;
  padding: 6px 10px !important;
}

input.datetime-width {
  width: 44%;
  font-size: var(--font-text-size);
}

button.MuiButtonBase-root.MuiIconButton-root.fullscreen {
  margin-right: 5px;

  &:hover {
    color: #044c92;
    background-color: #ffffff;
  }
}

div#schema-graph,
div#schema-graph2 {
  max-height: calc(100vh - 50px);
  @include custom-scroller("y");
  padding: 0px 10px;

  @media screen and (max-width: 767px) {
    padding: 0px 5px;
  }
}

div#schema-graph3 {
  padding: 0px 10px;
}

hr {
  border: 0.5px solid $primary;
  margin-bottom: 30px;
}

.dashboardtable {
  .arrowicon {
    max-height: 14px;
    //color: $primary;
    margin-right: -8px;
    cursor: pointer;
    color: $primary;

    &:hover {
      color: $primary;
    }
  }

  .arrowiconactive {
    max-height: 14px;
    color: $primary;
    margin-right: -8px;
    cursor: pointer;
  }

  .tableContainerRoot {
    height: calc(77vh - 350px);
    overflow-y: scroll;
    @include custom-scroller("y");

    @media screen and (max-width: 767px) {
      height: calc(77vh - 170px);
    }
  }

  @media screen and (max-width: 767px) {
    max-height: 454px;
    @include custom-scroller("xy");
  }

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    @include custom-scroller("xy");
  }

  @media screen and (max-width: 1300px) and (min-width: 1024px) {
    @include custom-scroller("xy");
  }
}

span.MuiTypography-root.MuiCardHeader-title.MuiTypography-body2.MuiTypography-displayBlock {
  text-transform: capitalize;
}

tr:nth-child(even) {
  // background-color: #d4d9dc !important;
}

.span.MuiTouchRipple-root {
  background: bisque !important;
}

.MuiTabs-indicator {
  height: 5px !important;
}

.MuiTableBody-root tr.MuiTableRow-root:hover td.MuiTableCell-root.MuiTableCell-body {
  color: var(--font-color) !important;
}

.MuiTableBody-root tr.MuiTableRow-root:hover {
  background: #c1c1c175 !important;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
}

a.schemalink {
  color: blue !important;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.icon-asc-schema {
  height: 14px;
  width: 6%;

  &:hover {
    background: #ffffff80;
    border-radius: 100px;
  }
}

.icon-desc-schema {
  height: 14px;
  width: 6%;

  &:hover {
    background: #ffffff80;
    border-radius: 100px;
  }
}

// rect {
//   fill: var(--background-color);
// }
// text {
//   fill: var(--font-color);
// }