@import "../../../../assets/themes/default/share.scss";
@import "../../../../assets/sass/global-import";

:root {
  --font-text-size: 12px;
}

.MuiCardHeader-root {
  align-items: end !important;

  .MuiCardHeader-content {
    .MuiCardHeader-title {
      color: var(--font-color) !important;
      font-size: var(--font-text-size) !important;
      font-weight: 600;
    }

    &.MuiTypography-colorTextSecondary {
      font-size: var(--font-text-size) !important;
    }

    .MuiTypography-body2 {

      font-size: var(--font-text-size) !important;
      font-weight: 600;
      word-spacing: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 245px;

      strong {
        font-size: 10px;
        color: #4a4a4af0;

        span {
          color: #777474;
          margin: 0px 3px;
        }
      }

      hr {
        border: none;
        margin: 2px 0px;
      }
    }
  }

  .MuiCardHeader-action {
    svg.MuiSvgIcon-root {
      color: $primary;
      font-size: 14px;
    }
  }
}

.Innerdetails {
  .infinite-scroll {
    margin-top: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 330px;
    min-height: 330px;
    @include custom-scroller("y");
  }

  .MuiGrid-spacing-xs-3 {
    padding: 5px 1px !important;

    .MuiPaper-elevation1 {
      box-shadow: 2px 3px 10px 1px rgba(0, 0, 0, 0.3);

      &:hover {
        box-shadow: 0px 0px 1px 1px $primary;
      }
    }
  }

  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit {
    padding: 0px;
  }
}

.roleaddpopup {
  .MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 0px 15px !important;
    height: 30px;
    background: var(--background-color);

    .MuiSvgIcon-root {
      font-size: 18px;
    }

    .MuiIconButton-root {
      padding-right: 0px;
      color: #655f65;

      &:hover {
        color: #000;
      }
    }
  }

  .add-lable {
    display: flex;
    align-items: center;
    padding: 12px 25px !important;

    .multi {
      // margin-bottom: 20px;

      .MuiFormGroup-root {
        display: block;
      }

      label {
        margin-bottom: 0px;

        .PrivateSwitchBase-root-404 {
          padding: 0px 8px;
        }
      }
    }
  }

  .MuiGrid-spacing-xs-6>.MuiGrid-item {
    padding: 6px 25px;

    span {
      color: var(--font-color);
      font-size: var(--font-text-size);
      font-weight: 500;
      font-family: $secondary-font;

      svg.MuiSvgIcon-root {
        padding: 0px;
        margin: 0px;
        width: 20px;
        height: 16px;
      }
    }

    .form-group {
      margin-bottom: 0rem;
      position: relative;

      .MuiInputBase-adornedStart {
        font-size: var(--font-text-size) !important;
      }

      .MuiFormControl-marginNormal {
        width: 100%;

        .MuiInput-formControl {
          padding: 0px 5px;
        }
      }

      span.MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-346.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-checked-347.Mui-checked.PrivateSwitchBase-disabled-348.Mui-disabled.MuiIconButton-colorPrimary.Mui-disabled.Mui-disabled {
        svg.MuiSvgIcon-root {
          font-size: 19px;
        }
      }

      input#standard-uncontrolled {
        font-size: 15px;
        font-family: $secondary-font;
        color: var(--font-color);
      }

      button.MuiButtonBase-root.MuiIconButton-root {
        position: absolute;
        right: 9px;
        padding: 0px;
        top: 10px;

        svg.MuiSvgIcon-root {
          color: $primary;
          font-size: 15px;
          width: 17px !important;
          height: 17px !important;
        }
      }
    }
  }

  .MuiFormControl-marginNormal {
    margin-top: 6px;
    margin-bottom: 6px;
    border: 1px solid #c2c2c2;
    border-radius: 4px;
  }

  .MuiDialog-paperScrollPaper {
    max-height: auto !important;
  }

  .MuiDialogContent-root {
    overflow-y: hidden !important;
  }

  .MuiDialog-paperWidthSm {
    background: var(--background-color);
    color: var(--font-color);
    max-width: 100%;
    width: 55%;
    box-shadow: 1px 1px 11px 3px rgba(0, 0, 0, 0.3);
    border-radius: 6px !important;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-primary.MuiButton-textPrimary {
    background: $primary;
    color: $secondary;
    font-size: 11px;

    &:focus {
      background: $primary;
    }

    &:hover {
      background: $primary  !important;
      border: $primary  !important;
    }
  }

  .roletile {
    align-items: center;
    display: flex;

    svg.MuiSvgIcon-root {
      background: $primary;
      border: 1px solid $primary;
      border-radius: 100px;
      padding: 1px;
      color: $secondary;
      width: 13px;
      height: 13px;
    }
  }

  .MuiPaper {
    background: var(--background-color);
    color: var(--font-color);
    border: 1px solid;
    height: 156px;
    overflow-y: auto;
    width: 100%;
    margin-bottom: 7px;
  }

  .btnclass {
    margin-left: -28%;
    margin-top: 6%;
    text-transform: none !important;
    width: 128px;
  }
}

.roledeletepopup {
  .MuiDialog-paperWidthSm {
    background: var(--background-color);
    color: var(--font-color);
    max-width: 100%;
    width: 30%;
    box-shadow: 1px 1px 11px 3px rgba(0, 0, 0, 0.3);
    border-radius: 6px !important;
  }

  .MuiDialogContent-root {
    overflow-y: unset;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-primary.MuiButton-textPrimary {
    background: $primary;
    color: $secondary;
    font-size: 11px;

    &:focus {
      background: $primary;
    }

    &:hover {
      background: $primary  !important;
      border: $primary  !important;
    }
  }

  .roletile {
    align-items: center;
    display: flex;

    svg.MuiSvgIcon-root {
      background: $primary;
      border: 1px solid $primary;
      border-radius: 100px;
      padding: 1px;
      color: $secondary;
      width: 13px;
      height: 13px;
    }
  }

  .MuiDialogActions-root.MuiDialogActions-spacing {
    padding: 8px 47px;
  }
}

.role-page {
  .MuiPaper-rounded {
    top: 51% !important;
    left: 18% !important;
    margin-top: 0px !important;

    @media screen and (max-width: 767px) {
      margin-top: 10px !important;
    }

    .MuiCardHeader-root {
      display: flex;
      padding: 5px 10px;

      .MuiCardHeader-avatar {
        margin-right: 7px;
        margin-top: 6px;

        .MuiAvatar-colorDefault {
          color: #fafafa;
          background-color: $primary;

          font-size: var(--font-text-size);
          text-transform: capitalize;
          width: 40px;
          height: 40px;
          font-weight: 600;
        }
      }
    }
  }
}

.MuiListItemIcon-root {
  min-width: 30px !important;

  svg.MuiSvgIcon-root {
    width: 17px;
    color: var(--font-color) !important;
    margin: 0px;
    padding: 0px;
  }
}

.MuiDialog-paperWidthSm .MuiDialogTitle-root {
  padding-left: 13px !important;

  .MuiTypography-h6 {
    color: var(--font-color);
    margin: 7px;
    font-size: 13px;
    font-family: $secondary-font;
  }
}

.MuiListItemText-root {
  .MuiTypography-body1 {
    font-size: var(--font-text-size) !important;
    font-weight: 500;
    color: var(--font-color) !important;
  }

  .MuiPaper-elevation8 {
    box-shadow: 2px 1px 7px 1px rgba(158, 153, 153, 0.26) !important;
  }

  .profile-menu svg.MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 10px;
  }
}

.MuiGrid-spacing-xs-3 {
  &.MuiGrid-item {
    @media screen and (max-width: 767px) {
      padding: 0px !important;
    }
  }
}

.addresses {
  @media screen and (max-width: 767px) {
    width: 42px !important;
  }
}

.dashboard-cover {
  @media screen and (max-width: 767px) {
    margin-left: 1% !important;
  }

  @media screen and (max-width: 1024px) and (min-width: 768px) {
    margin-left: 1% !important;
  }

  @media screen and (max-width: 1300px) and (min-width: 1024px) {
    margin-left: 1% !important;
  }

  width: 100% !important;
  margin-top: 0% !important;
}

.dynamic-table {
  @media screen and (max-width: 480px) {
    max-height: calc(79vh - 42px);
  }
}

.mainview {
  margin: 0px;
  margin-bottom: 0px;
  overflow: hidden;

  .maincontent {
    background: $secondary;
    display: flex;

    .dashboardcover {
      width: calc(100% - 2px);
      background: var(--background-color);

      &.hidesidebar {
        width: calc(100% - 40px);

        @media screen and (max-width: 767px) {
          width: 100%;
          margin-left: 1%;
          margin-top: 14%;
        }

        @media screen and (max-width: 1024px) and (min-width: 768px) {
          width: 100%;
          margin-left: 2%;
          margin-top: 6%;
        }

        @media screen and (max-width: 1300px) and (min-width: 1024px) {
          width: 100%;
          margin-left: 2%;
          margin-top: 5%;
        }
      }
    }
  }
}