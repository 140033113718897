@import "../../../../assets/sass/styles.scss";

:root {
  --background-color: #fff;
  --selected_background_color: #c7e0f4;
  --font-color: #000000;
}

.mainside {
  .sidebar-cover {
    //   .makeStyles-appBarShift-3 {
    //     width: calc(100% - 165px);
    //     .MuiToolbar-regular {
    //       min-height: 45px !important;
    //       .MuiIconButton-root {
    //         padding: 10px;
    //         .MuiIconButton-label {
    //           svg.MuiSvgIcon-root {
    //             background: #044c92;
    //             border-radius: 100%;
    //             color: #fff;
    //           }
    //         }
    //       }
    //     }
    //   }
    //   .makeStyles-drawerOpen-7 {
    //     width: 164px;
    //     @media (min-width: 600px) {
    //     }
    //     .makeStyles-toolbar-9 {
    //       min-height: 45px !important;
    //       @media (min-width: 600px) {
    //         min-height: 45px !important;
    //       }
    //       min-height: 45px;
    //       .MuiIconButton-root {
    //         padding: 10px;
    //         svg.MuiSvgIcon-root {
    //           color: $primary;
    //           background: $secondary;
    //           border-radius: 100%;
    //         }
    //       }
    //     }
    //   }
  }

  li {
    padding: 3px 0px !important;

    .link {
      display: flex;
      text-decoration: none;
      padding: 5px 5px;
      box-sizing: border-box;

      .MuiListItemIcon-root {
        display: block;
        padding-left: 6px;

        svg.MuiSvgIcon-root {
          // color: #fff !important;
          color: var(--font-color);
          width: 18px !important;
        }
      }

      .MuiListItemText-root {
        .MuiTypography-body1 {
          // color: #fff !important;
          color: var(--font-color);
          font-size: 13px;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
            "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, sans-serif !important;
          -webkit-font-smoothing: antialiased !important;
          -moz-osx-font-smoothing: grayscale !important;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: 0.00938em;
        }
      }

      &.active {
        // background: rgba(0, 0, 0, 0.2);
        background: var(--selected_background_color);
        //color: $selected_font_color !important;

        border-left: 2px solid #fff;

        path {
          color: $selected_font_color;
        }

        .MuiListItemText-primary {
          color: $selected_font_color;
        }
      }
    }
  }

  @media (min-width: 600px) {
    .MuiToolbar-regular {
      min-height: 49px !important;

      .MuiTypography-noWrap {
        width: 100%;
      }
    }
  }

  // .makeStyles-toolbar-9 {
  //   min-height: 45px !important;
  // }
  // .makeStyles-drawerClose-8 {
  //   @media (min-width: 600px) {
  //     background: $primary;
  //     width: 50px;
  //   }
  //   .MuiListItemText-root {
  //     display: none;
  //   }
  //   .MuiIconButton-edgeEnd {
  //     display: none;
  //   }
  // }
  // .makeStyles-drawerOpen-7 {
  //   @media (min-width: 600px) {
  //     background: $primary;
  //     width: 190px;
  //   }
  // }
  // .makeStyles-appBarShift-3 {
  //   width: calc(100% - 190px);
  // }
  .MuiPaper-root.MuiExpansionPanel-root.MuiExpansionPanel-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    // background: #044c92;
    background: var(--background-color);
    border: none;
    box-shadow: none;
    margin: 0px;

    .MuiExpansionPanelSummary-root {
      display: flex;
      padding: 0px 0px 0 0px;
      min-height: 30px;
      justify-content: space-between;

      .MuiExpansionPanelSummary-content.Mui-expanded {
        margin: 0px 0;

        .link.admin-link {
          display: flex;
          text-decoration: none;
          justify-content: space-between;

          .MuiListItemIcon-root {
            display: block;

            svg.MuiSvgIcon-root {
              width: auto;
              color: #fff;
            }
          }

          .MuiTypography-body1 {
            color: var(--font-color);
            font-size: 13px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
              "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, sans-serif !important;
            -webkit-font-smoothing: antialiased !important;
            -moz-osx-font-smoothing: grayscale !important;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.00938em;
          }
        }
      }

      .MuiIconButton-edgeEnd {
        color: #fff;
        padding: 0px 0px 0px 0px;
      }

      .MuiExpansionPanelSummary-expandIcon {
        margin: 0px 0px 0px 0px;

        .MuiSvgIcon-root {
          color: var(--font-color);
          background: none;
          border-radius: 0px;
        }
      }
    }
  }

  div#panel1c-header {
    .MuiExpansionPanelSummary-content {
      margin: 0px;

      .link.admin-link {
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        align-items: center;

        .MuiListItemIcon-root {
          display: block;

          svg.MuiSvgIcon-root {
            // color: #fff;
            color: var(--font-color);
            width: auto;
          }
        }

        .MuiListItemText-root {
          margin-top: 2px;

          .MuiTypography-body1 {
            // color: #fff !important;
            color: var(--font-color);
            font-size: 13px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
              "Fira Sans", "Droid Sans", "Helvetica Neue", Helvetica, sans-serif !important;
            -webkit-font-smoothing: antialiased !important;
            -moz-osx-font-smoothing: grayscale !important;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.00938em;
          }
        }
      }
    }
  }

  .MuiExpansionPanelDetails-root {
    display: flex;
    padding: 0px 0px 0px;

    li.menu-link {
      list-style: none;
    }
  }

  li.menu-link.logoutitem {
    position: fixed;
    left: 0;
    bottom: 0;
  }

  .makeStyles-menuButton-4 {
    margin-right: 10px;
  }

  .MuiIconButton-edgeStart {
    margin-left: -26px;
  }
}

span.logo img {
  // width: 35px;
  height: 33px;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
  // background: #044c92;
  background: var(--background-color);
  width: $sidebar_width;
  position: relative;
  height: calc(100vh - 60px);
  top: 0;
  transition: 0.3s;
  overflow: hidden;
  // .MuiIconButton-root {
  //   .MuiSvgIcon-root {
  //     background: $secondary;
  //     border-radius: 100%;
  //     color: $primary;
  //   }
  // }
  // .toolbar_open {
  //   background: $primary;
  //   .MuiIconButton-root {
  //     float: right;
  //   }
  // }

  // .toolbar_close {
  //   background: $primary;
  //   .MuiIconButton-root {
  //     padding: 6px 14px;
  //   }
  // }
  // svg.MuiSvgIcon-root.menubtn {
  //   background: $primary !important;
  //   color: #fff !important;
  // }
}

.closestrip {
  .MuiListItemText-root {
    display: none;
  }

  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    // max-width: $sidebar_collapse_width !important;
    width: $sidebar_collapse_width  !important;
    overflow: hidden;
    position: relative;
    height: calc(100vh - 45px);
    top: 0;
    transition: 0.3s;

    @media screen and (max-width: 767px) {
      width: 45px;
    }
  }
}

// .appBar-open {
//   @media (min-width: 600px) {
//     .MuiToolbar-gutters {
//       padding-left: 149px !important;
//     }
//   }
//   @media screen and (max-width: 767px) {
//     .MuiToolbar-regular {
//       min-height: 56px;
//       display: flex;
//       justify-content: space-between;
//     }
//   }
// }

// .appBar-close {
//   .MuiToolbar-gutters {
//     @media (min-width: 600px) {
//       padding-left: 80px !important;
//     }
//     @media screen and (max-width: 767px) {
//       padding-left: 65px !important;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//     }
//   }
// }
.rightmenu {
  width: 100%;
}

a.link:hover {
  background: var(--hover_background_color);
}

.adminsidebar {
  display: flex;
  justify-content: space-between;
}