@import "../../../../assets/themes/default/share.scss";
@import "../../../../assets/sass/styles.scss";

:root {
    --font-text-size: 12px;
    --background-color: #fff;
    --color: #0078d4;
    --border_color: #ccc;
    --font-color: #000000;
}

.SubHeader {
    background-color: var(--background-color);

    .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
        margin: 0px;
        // border-color: var(--border_color);
        // border-bottom-style: solid;
        // border-bottom-width: 1px;
        height: 70px;
        font-size: 27px;
        font-weight: 700;
        border-bottom: solid 1px var(--border_color);
        padding: 0 26px;
        justify-content: space-between;

        @media screen and (max-width: 767px) {
            display: block;
            height: auto;
            padding: 10px 5px;
        }

        .sub-header-text {
            font-size: 27px;
            font-weight: 500;
            color: var(--color);
            ;

            @media screen and (max-width: 767px) {
                font-size: 18px;
            }
        }

        .btn-primary {
            height: 35px;
            max-width: 100%;
            margin-right: 10px;
            border-radius: 4px;
            //   border: 1px solid var(--border_color);
            background-color: var(--btn_background_color);
            font-size: var(--font-text-size);
            color: white;
            text-transform: capitalize;
            cursor: pointer;

            @media screen and (max-width: 767px) {
                max-width: 60%;
                margin-left: 1%;
            }
        }

        .compare {
            background-color: white !important;
            color: var(--btn_background_color) !important;
            border-color: var(--btn_background_color) !important;
            border: 1px solid;
        }

        .subheader-filter {
            display: flex;
            align-items: flex-end;
            height: 50%;

            @media screen and (max-width: 767px) {
                display: block;
                width: 80%;
            }

            .search-bar {
                margin-right: 10px;
                font-weight: normal;
            }

            .MuiPaper-elevation1.MuiPaper-rounded {
                width: 33%;
                height: 35px;

                @media screen and (max-width: 767px) {
                    width: 72%;
                }
            }

            .file-section {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                .file-name {
                    margin-right: 10px;
                    font-size: var(--font-text-size);
                    font-weight: 400;
                    line-height: 1.5;
                }

                .MuiButton-contained {
                    background-color: var(--btn_background_color);
                    color: $secondary;
                }

                .MuiButton-containedSizeLarge {
                    padding: 2px 5px;
                    font-size: 0.9375rem;

                    @media screen and (max-width: 767px) {
                        font-size: 11px !important;
                    }

                    .labelspan {
                        text-transform: capitalize;
                        border-right: 1px solid var(--border_color);
                        padding: 0px 5px;
                        font-size: var(--font-text-size);
                    }

                    & svg.MuiSvgIcon-root {
                        font-size: $icon_font_size;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .user-filter {
        margin: 2px 25px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        @media screen and (max-width: 767px) {
            margin: 3px;
            width: 100%;
        }

        .statuslabel {
            margin-right: 7px;
            font-size: var(--font-text-size);
            font-weight: bold;
            margin-bottom: 3%;
        }

        .filter-ddl {
            height: 19px;
        }

        select.form-control {
            color: var(--font-color);
            align-items: center;
            background-color: var(--background-color);
            cursor: default;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            // min-height: 29px;
            position: relative;
            box-sizing: border-box;
            border-color: var(--border_color);
            border-radius: 5px;
            border-style: solid;
            border-width: 1px;
            transition: all 100ms ease 0s;
            outline: 0px !important;
            margin-top: -15%;
            height: 35px;
            padding: 0px 7px;
            font-size: var(--font-text-size);
        }
    }

    .ForwardRef-root-132 {
        height: 35px;
    }
}