.CreateForm {
    overflow: hidden;
}

form.CreateClusterNode-form {
    width: 100%;
    padding: 0px 0px 0px 8px;

    .ClusterNodeHeadingDiv {
        font-weight: 700;
        font-size: 14px;
        color: #0078d4;
        // padding: 0px 0px 9px 0px;

    }

    .ClusterNodemainDiv {
        display: flex;
        flex-direction: column;
        gap: 10px;
        // padding: 0px 0px 10px 0px;

        .buttonDiv {
            display: flex;
            grid-gap: 10px;
            gap: 10px;
            padding: 16px 125px;

            justify-content: center;
            align-items: center;

            @media screen and (min-width:320px) and (max-width:425px) {
                padding: 13px 0px 0px 0px;
            }

            @media screen and (min-width:426px) and (max-width:768px) {
                padding: 16px 0px 9px 0px;
            }

            button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.saveBtn {
                background-color: #0078d4;

                @media screen and (min-width:320px) and (max-width:425px) {
                    height: 4vh;
                    width: 24vw;
                    font-size: 13px;
                }
            }

            .saveBtn {
                span.MuiButton-label {
                    font-weight: 700;
                    color: white;
                }
            }

            .btn-primary {
                @media screen and (min-width:320px) and (max-width:425px) {
                    height: 4vh;
                    width: 24vw;
                    font-size: 13px;
                }

                span.MuiButton-label {
                    font-weight: 700;
                    color: blackF;

                }
            }


        }

        .nodeIdIv {
            display: flex;
            align-items: center;
            gap: 16px;
            margin-top: 5px;
            justify-content: space-between;

            @media screen and (min-width:426px) and (max-width:768px) {
                gap: 3px;
            }

            @media screen and (min-width:320px) and (max-width:425px) {
                gap: 3px;
            }

            .nodeIdHeading {
                font-weight: 720;
                font-size: 12px;
                color: #000;

                @media screen and (min-width:320px) and (max-width:425px) {
                    font-size: 11px;
                    max-width: 31%;
                }

                @media screen and (min-width:426px) and (max-width:768px) {
                    font-size: 13px;
                    max-width: 31%;
                }
            }

            .nodeIdinput.select {
                .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                    width: 100%;
                    border: 1.5px solid #ccc;
                    border-radius: 5px;
                    padding: 2.5px;
                }

                .MuiInput-underline:before {
                    border-bottom: 0px !important;
                }
            }

            .nodeIdinput {
                margin: 5px 0px;
                width: 72%;

                @media screen and(min-width:426px) and (max-width:768px) {
                    .MuiOutlinedInput-inputMarginDense {
                        padding-top: 9.5px;
                        padding-bottom: 9.5px;
                        font-size: 13px;
                    }

                    div#demo-select-small {
                        padding: 4px;
                    }
                }

                @media screen and (min-width:320px) and (max-width:425px) {
                    width: 62%;

                    .MuiOutlinedInput-inputMarginDense {
                        padding-top: 7.5px;
                        padding-bottom: 7.5px;
                        font-size: 12px;
                    }

                    div#demo-select-small {
                        padding: 4px;
                    }
                }


                .MuiFormControl-root.MuiTextField-root {
                    width: 100%;

                }

                .NodeInputWidth {
                    width: 100%;
                    padding: 4px;

                }


            }


        }


    }


}

.textDiv {
    font-size: 12.5px;
    text-align: end;
    margin-top: 5px;
}


.viewCredential {
    overflow-x: hidden;

    .CreateClusterNode-form {
        width: 100%;
        padding: 0px 0px 0px 8px;

        .ClusterNodeHeadingDiv {
            font-family: Arial;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #0078d4;
            // padding: 0px 0px 9px 0px;

        }

        .ClusterNodemainDiv {
            display: flex;
            flex-direction: column;
            gap: 10px;
            // padding: 0px 0px 10px 0px;

            .buttonDiv {
                display: flex;
                gap: 8px;
                padding: 20px 0px 0px 0px;
                justify-content: flex-start;
                align-items: flex-start;
                float: right;

                .CloseBtn {
                    float: right;
                }

                button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.saveBtn {
                    background-color: #0078d4;

                }


                .btn-primary {
                    span.MuiButton-label {
                        font-weight: 700;
                    }

                    border-radius: 3px;
                    background-color: #707070;
                }

                .EditBtn {
                    .btn-primary {
                        span.MuiButton-label {
                            font-weight: 700;
                        }

                        border-radius: 5px;
                        background-color: #0078d4;

                        color: #fff;
                    }
                }


            }

            .nodeIdIv {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 2px;

                .nodeIdHeading {
                    font-weight: 700 !important;
                    font-family: Arial;
                    font-size: 12px;
                    /* font-weight: bold; */
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.25;
                    letter-spacing: 0.2px;
                    color: #000;
                }

                .nodeIdinput.select {
                    .MuiInputBase-root.MuiInput-root.MuiInput-underline {
                        width: 100%;
                        border: 1.5px solid #ccc;
                        border-radius: 5px;
                        padding: 2.5px;
                    }

                    .MuiInput-underline:before {
                        border-bottom: 0px !important;
                    }
                }

                .nodeIdinput {
                    margin: 5px 0px;
                    width: 75%;
                    font-size: 12px;

                    .MuiFormControl-root.MuiTextField-root {
                        width: 100%;

                    }

                    .NodeInputWidth {
                        width: 100%;
                        padding: 4px;

                    }


                }


            }


        }


    }
}



.clusterCompare {
    @media screen and (min-width:768px) {
        .subheader-filter {
            width: 73% !important;
            justify-content: flex-end !important;
        }
    }
}

.viewNode {
    .CloseBtn {
        padding-left: 9px;
    }

    .saveBtn {
        color: white;
    }
}

.credentialEdit {
    padding: 10px 14px !important;

    .credentialEditMain {
        width: 100%;

        .closeBtnDiv {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .heading {
                // font-family: Arial;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #0078d4;
            }
        }

        .credentialContentDiv {
            .nodeIdIv {
                display: flex;
                align-items: center;
                gap: 20px;
                justify-content: space-between;

                .nodeIdHeading {
                    font-size: 15px;
                    font-weight: 700 !important;
                    // font-family: Arial;
                    // font-size: 15px;
                    /* font-weight: bold; */
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.25;
                    letter-spacing: 0.2px;
                    color: #000;
                }

                .nodeIdinput {
                    margin: 5px 0px;

                }
            }
        }

        .BtnDiv {
            padding: 5px;

            .btn-primary {
                background-color: rgb(0, 120, 212) !important;

                span.MuiButton-label {
                    font-weight: 700 !important;
                    color: white !important;
                }
            }

        }

    }
}