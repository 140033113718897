@import "../../../../assets/themes/default/share.scss";
@import "../../../../assets/sass/global-import";

:root {
  --font-text-size: 12px;
}

.setting-page {
  .MuiPaper-rounded {
    top: 51% !important;
    left: 18% !important;
    margin-top: 0px !important;

    @media screen and (max-width: 480px) {
      width: 100%;
      margin: 3px 1px;
      height: 35px
    }

    @media screen and (max-width: 767px) {
      margin-top: 10px !important;
    }

    .MuiCardHeader-root {
      display: flex;
      padding: 5px 10px;

      .MuiCardHeader-avatar {
        margin-right: 7px;
        margin-top: 6px;

        .MuiAvatar-colorDefault {
          color: #fafafa;
          background-color: $primary;

          font-size: var(--font-text-size);
          text-transform: capitalize;
          width: 40px;
          height: 40px;
          font-weight: 600;
        }
      }
    }
  }

  .SubHeader {
    .subheader-filter {
      width: 40%;
    }

    .user-filter .filter-ddl {
      height: 20px;
    }
  }


}