@import "../../../../assets/themes/default/share.scss";
@import "../../../../assets/sass/global-import";

:root {
  --font-text-size: 12px;
}

.MuiCardHeader-root {
  align-items: end !important;

  .MuiCardHeader-content {
    .MuiCardHeader-title {
      // color: $primary;
      color: var(--font-color) !important;
      font-size: var(--font-text-size) !important;
      font-weight: 600;
    }

    &.MuiTypography-colorTextSecondary {
      font-size: var(--font-text-size) !important;
    }

    .MuiTypography-body2 {

      font-size: var(--font-text-size) !important;
      font-weight: 600;
      word-spacing: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 245px;

      strong {
        font-size: 10px;
        color: #4a4a4af0;

        // font-size: 10px;
        // color: #000000f0;
        span {
          color: #777474;
          margin: 0px 3px;
        }

        // &::after {
        //   content: ",";
        // }
      }

      hr {
        border: none;
        margin: 2px 0px;
      }
    }
  }

  .MuiCardHeader-action {
    svg.MuiSvgIcon-root {
      color: $primary;
      font-size: 14px;
    }
  }
}

.Innerdetails {
  .user-filter-block {
    margin: 20px 0px !important;
    padding: 0px 15px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);

    @media screen and (max-width: 767px) {
      box-sizing: border-box;
      margin: 20px 12px !important;
      display: block;
      align-items: center;
      text-align: left;
    }

    .formControl-select {
      width: 150px;
      margin: 15px 0px;

      @media screen and (max-width: 767px) {
        width: 75%;
      }

      .MuiSelect-icon {
        color: $font_color  !important;
      }

      .MuiOutlinedInput-input {
        // padding: 14.5px 8px !important;
        border-radius: 2px;
      }

      .MuiInputBase-root {
        line-height: 2px;
      }

      .MuiInputBase-input {
        font-size: var(--font-text-size) !important; //13px;
        height: 40px !important;
      }

      .MuiSelect-outlined {
        padding: 20px 64px 10px 0px !important;
      }
    }

    .formControl-radioButton {
      margin: 15px 0px;

      @media screen and (max-width: 767px) {
        width: 75%;
      }

      .MuiTypography-body1 {
        font-size: var(--font-text-size) !important; //13px;
        font-family: Helvetica, sans-serif;
        font-weight: 400;
        line-height: 0.5;
        color: var(--font-color);
      }

      .MuiSvgIcon-root {
        font-size: 16px;
        color: var(--font-color);
      }
    }

    .MuiFormControl-root {
      @media screen and (max-width: 767px) {
        min-width: 75%;
      }
    }
  }

  .infinite-scroll {
    //border: 1px solid black;
    margin-top: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 330px;
    min-height: 330px;
    @include custom-scroller("y");
  }

  .MuiGrid-spacing-xs-3 {
    padding: 5px 1px !important;

    .MuiPaper-elevation1 {
      box-shadow: 2px 3px 10px 1px rgba(0, 0, 0, 0.3);

      &:hover {
        box-shadow: 0px 0px 1px 1px $primary;
      }
    }
  }

  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit {
    padding: 0px;
  }
}

// .MuiGrid-spacing-xs-6>.MuiGrid-item {
//     padding: 0px 10px !important;

//     .MuiFormControl-marginNormal {
//         margin-top: 16px;
//         margin-bottom: 8px;
//         min-width: 100% !important;

//         .MuiInput-formControl {
//             input#standard-uncontrolled {
//                 font-size: $font-text-size;
//             }
//         }
//     }

// }

// .multi {
//     padding: 20px 0px !important;

//     label#demo-mutiple-chip-label {
//         font-size: $font-text-size;
//     }
// }
.useraddpopup {
  .MuiDialogTitle-root {
    flex: 0 0 auto;
    margin: 0;
    padding: 0px 15px !important;
    height: 30px;
    background: #797a7b36;

    .MuiSvgIcon-root {
      font-size: 18px;
    }

    .MuiIconButton-root {
      padding-right: 0px;
      color: #655f65;

      &:hover {
        color: #000;
      }
    }
  }

  .add-lable {
    display: flex;
    align-items: center;
    padding: 13px 25px !important;

    .multi {

      //margin-bottom: 20px;
      .Role-multi-select {
        height: 43px;
      }

      .MuiFormGroup-root {
        display: block;
      }

      label {
        margin-bottom: 0px;

        .PrivateSwitchBase-root-404 {
          padding: 0px 8px;
        }
      }
    }
  }

  .MuiGrid-spacing-xs-6>.MuiGrid-item {
    padding: 6px 25px;

    span {
      font-size: var(--font-text-size);
      font-weight: 500;
      font-family: $secondary-font;

      svg.MuiSvgIcon-root {
        padding: 0px;
        margin: 0px;
        width: 20px;
        height: 16px;
      }
    }

    .form-group {
      // margin-bottom: 1%;
      position: relative;

      .checkbox-control {
        margin-left: -15%;
      }

      .MuiInputBase-adornedStart {
        font-size: var(--font-text-size) !important;
      }

      .MuiFormControl-marginNormal {
        width: 100%;

        .MuiInput-formControl {
          padding: 0px 5px;
        }
      }

      span.MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-346.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-checked-347.Mui-checked.PrivateSwitchBase-disabled-348.Mui-disabled.MuiIconButton-colorPrimary.Mui-disabled.Mui-disabled {
        svg.MuiSvgIcon-root {
          font-size: 19px;
        }
      }

      input#standard-uncontrolled {
        font-size: var(--font-text-size);
        font-family: $secondary-font;
        color: var(--font-color);
      }

      button.MuiButtonBase-root.MuiIconButton-root {
        position: absolute;
        right: 9px;
        padding: 0px;
        top: 10px;

        svg.MuiSvgIcon-root {
          color: $primary;
          font-size: var(--font-text-size);
          width: 17px !important;
          height: 17px !important;
        }
      }
    }
  }

  .MuiFormControl-marginNormal {
    margin-top: 6px;
    margin-bottom: 6px;
    border: 1px solid #c2c2c2; // #797a7b;
    border-radius: 4px;
  }

  .MuiDialog-paperScrollPaper {
    max-height: auto !important;
  }

  .MuiDialogContent-root {

    // overflow-y: hidden !important;
    @media screen and (max-width: 767px) {
      @include custom-scroller("xy");
    }
  }

  .MuiDialog-paperWidthSm {
    background: var(--background-color);
    color: var(--font-color);
    max-width: 100%;
    width: 55%;
    box-shadow: 1px 1px 11px 3px rgba(0, 0, 0, 0.3);
    border-radius: 6px !important;

    @media screen and (max-width: 767px) {
      width: 100%;
      ;
    }

    .userroles {
      width: 169px;
      display: grid;
      max-width: 172px;
      margin: 0 auto;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2px;

      @media screen and (max-width: 1024px) {
        margin: 0;
      }

      .form-group {
        display: grid;
        max-width: 960px;
        margin: 0 auto;
        grid-template-columns: repeat(3, 1fr);
      }

      .MuiChip-root.MuiChip-colorPrimary {
        height: 23px;
        margin: 0px 3px;
        cursor: pointer;
      }

      .MuiChip-root.MuiChip-colorPrimary svg.MuiSvgIcon-root.MuiChip-icon.MuiChip-iconColorPrimary {
        font-size: var(--font-text-size) !important;
        background: #fff;
        color: #044c92;
        border-radius: 100%;
        padding: 1px;
      }

      .MuiChip-root.MuiChip-colorPrimary span.MuiChip-label {
        font-size: var(--font-text-size) !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 66px;
        text-align: center;
      }
    }
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-primary.MuiButton-textPrimary {
    background: $primary;
    color: $secondary;
    font-size: 11px;

    &:focus {
      background: $primary;
    }

    &:hover {
      background: $primary  !important;
      border: $primary  !important;
    }
  }

  .usertile {
    align-items: center;
    display: flex;
    color: var(--font-color);

    svg.MuiSvgIcon-root {
      background: $primary;
      border: 1px solid $primary;
      border-radius: 100px;
      padding: 1px;
      color: $secondary;
      width: 13px;
      height: 13px;

      .userIcon {
        background: #0078d4;
        border: 1px solid #0078d4;
        border-radius: 100px;
        padding: 1px;
        color: #fff;
        width: 13px;
        height: 13px;
      }
    }

  }

  .MuiDialogActions-root.MuiDialogActions-spacing {
    padding: 8px 47px;
  }
}

.userdeletepopup {
  .MuiFormControl-marginNormal {
    margin-top: 6px;
    margin-bottom: 6px;
    border: 1px solid #c2c2c2; // #797a7b;
    border-radius: 4px;
  }

  .MuiDialog-paperWidthSm {
    background: var(--background-color);
    color: var(--font-color);
    max-width: 100%;
    width: 30%;
    box-shadow: 1px 1px 11px 3px rgba(0, 0, 0, 0.3);
    border-radius: 6px !important;
  }

  .MuiDialogContent-root {
    overflow-y: unset;
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn-primary.MuiButton-textPrimary {
    background: $primary;
    color: $secondary;
    font-size: 11px;

    &:focus {
      background: $primary;
    }

    &:hover {
      background: $primary  !important;
      border: $primary  !important;
    }
  }

  .usertile {
    align-items: center;
    display: flex;

    svg.MuiSvgIcon-root {
      background: $primary;
      border: 1px solid $primary;
      border-radius: 100px;
      padding: 1px;
      color: $secondary;
      width: 13px;
      height: 13px;
    }
  }

  .MuiDialogActions-root.MuiDialogActions-spacing {
    padding: 8px 47px;
  }
}

.user-page {
  .MuiPaper-rounded {
    top: 51% !important;
    left: 18% !important;
    margin-top: 0px !important;

    @media screen and (max-width: 480px) {
      width: 100%;
      margin: 3px 1px;
      height: 35px
    }

    @media screen and (max-width: 767px) {
      margin-top: 10px !important;
    }

    .MuiCardHeader-root {
      display: flex;
      padding: 5px 10px;

      .MuiCardHeader-avatar {
        margin-right: 7px;
        margin-top: 6px;

        .MuiAvatar-colorDefault {
          color: #fafafa;
          background-color: $primary;

          font-size: var(--font-text-size);
          text-transform: capitalize;
          width: 40px;
          height: 40px;
          font-weight: 600;
        }
      }
    }
  }
}

.MuiListItemIcon-root {
  min-width: 30px !important;

  svg.MuiSvgIcon-root {
    width: 17px;
    color: var(--font-color) !important;
    margin: 0px;
    padding: 0px;
  }
}

.MuiDialog-paperWidthSm .MuiDialogTitle-root {
  padding-left: 13px !important;

  .MuiTypography-h6 {
    margin: 7px;
    font-size: var(--font-text-size) !important;
    font-family: $secondary-font;
  }
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  font-weight: 500 !important;
  height: 42px;
}

.MuiListItemText-root {
  .MuiTypography-body1 {
    font-size: var(--font-text-size) !important;
    font-weight: 500;
    color: var(--font-color) !important;
  }

  .MuiPaper-elevation8 {
    box-shadow: 2px 1px 7px 1px rgba(158, 153, 153, 0.26) !important;
  }

  .profile-menu svg.MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 10px;
  }
}

.MuiGrid-spacing-xs-3 {
  &.MuiGrid-item {
    @media screen and (max-width: 767px) {
      padding: 0px !important;
    }
  }
}

.addresses {
  @media screen and (max-width: 767px) {
    width: 42px !important;
  }
}

.dashboard-cover {
  width: 100% !important;
  margin-top: 0% !important;
}

.dynamic-list .dynamic-table {
  @media screen and (max-width: 480px) {
    max-height: calc(55vh - 42px);
  }
}

ul.MuiList-root.MuiMenu-list.MuiList-padding {
  background: var(--background-color);
}

.mainview {
  margin: 0px;
  margin-bottom: 0px;
  overflow: hidden;

  .maincontent {
    background: $secondary;
    display: flex;

    .dashboardcover {
      width: calc(100% - 2px);
      background: var(--background-color);

      &.hidesidebar {
        width: calc(100% - 40px);

        @media screen and (max-width: 767px) {
          width: 100%;
          margin-left: 1%;
          margin-top: 14%;
        }

        @media screen and (max-width: 1024px) and (min-width: 768px) {
          width: 100%;
          margin-left: 2%;
          margin-top: 6%;
        }

        @media screen and (max-width: 1300px) and (min-width: 1024px) {
          width: 100%;
          margin-left: 2%;
          margin-top: 5%;
        }
      }
    }
  }
}

.MuiDialogContent-dividers {
  min-height: 50px;
}