//@import '../../../../assets/sass/styles.scss';
@import "../../../assets/themes/default/share.scss";

:root {
  --font-text-size: 12px;
  --font-color: #000000;
}

$icon_font_size: 18px;

.dashboard-cover {
  background: var(--background-color);

}

.basic-multi-select {
  color: #000000;
}

.MuiPaper-root.paging-tab.MuiPaper-elevation1 {
  margin: 0px 13px;
}

.container.select__value-container--is-multi.css-1hwfws3 {
  font-size: 9px;
}

.basic-multi-select {
  font-size: 10px;
  height: 25px !important;
}

.envelope-filter {
  padding-bottom: 10px;

  .filter {
    label {
      text-align: right;
      font-size: var(--font-text-size);
      float: right;
      align-items: center;
      margin-top: 10px;
      font-weight: 600;
      // color:$primary ;
      color: var(--font-color);
    }

    button {
      margin-top: 3px;
      text-transform: capitalize;
    }

    span,
    svg {
      line-height: 20px;
      height: 20px;
      color: var(--font-color);
    }

    .select__placeholder.css-1wa3eu0-placeholder {
      font-size: var(--font-text-size);
    }

    span.MuiButton-label {
      // color: $secondary !important;
      color: var(--font-color) !important;
      font-weight: 600;
    }
  }
}

.modalpopoup {
  table.statusinfo {
    width: 100%;
  }
}

.filter-section {
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: flex-end;
  }
}

.filter span,
.filter svg {
  color: var(--font-color) !important;
}

.MuiTableBody-root .action-icon .MuiSvgIcon-root {
  color: var(--font-color);
  // &:hover {
  //   color:$primary !important;
  //   path{
  //     color:$primary;
  //   }
  // }
}

//   .MuiTableBody-root tr.MuiTableRow-root:hover .MuiSvgIcon-root
//   {
// color:$font-color;
//   }
.MuiDialogTitle-root .MuiIconButton-root .MuiSvgIcon-root {
  color: var(--font-color);
}

.MuiButton-sizeSmall {
  &:hover {
    background: $primary !important;
  }
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  background: var(--background-color);
  color: var(--font-color);
}